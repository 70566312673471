import React from "react";

type DropdownOption = { value: string; label: string | JSX.Element };

export const directorOptions: DropdownOption[] = [
  { value: "J.J. Abrams", label: "Abrams, J.J." },
  { value: "Alexandre Aja", label: "Aja, Alexandre" },
  { value: "Woody Allen", label: "Allen, Woody" },
  { value: "Pedro Almodóvar", label: "Almodóvar, Pedro" },
  { value: "Robert Altman", label: "Altman, Robert" },
  { value: "Sean Anders", label: "Anders, Sean" },
  { value: "Wes Anderson", label: "Anderson, Wes" },
  { value: "Darren Aronofsky", label: "Aronofsky, Darren" },
  { value: "Miguel Arteta", label: "Arteta, Miguel" },
  { value: "David Ayer", label: "Ayer, David" },
  { value: "Michael Bay", label: "Bay, Michael" },
  { value: "Peter Berg", label: "Berg, Peter" },
  { value: "Ingmar Bergman", label: "Bergman, Ingmar" },
  { value: "Luc Besson", label: "Besson, Luc" },
  { value: "Danny Boyle", label: "Boyle, Danny" },
  { value: "Kenneth Branagh", label: "Branagh, Kenneth" },
  { value: "Steven Brill", label: "Brill, Steven" },
  { value: "Mel Brooks", label: "Brooks, Mel" },
  { value: "Coen Brothers", label: "Brothers, Coen" },
  { value: "Tim Burton", label: "Burton, Tim" },
  { value: "James Cameron", label: "Cameron, James" },
  { value: "Martin Campbell", label: "Campbell, Martin" },
  { value: "John Carpenter", label: "Carpenter, John" },
  { value: "Steve Carr", label: "Carr, Steve" },
  { value: "D.J. Caruso", label: "Caruso, D.J." },
  { value: "John Cassavetes", label: "Cassavetes, John" },
  { value: "Jay Chandrasekhar", label: "Chandrasekhar, Jay" },
  { value: "Charlie Chaplin", label: "Chaplin, Charlie" },
  { value: "George Clooney", label: "Clooney, George" },
  { value: "Rob Cohen", label: "Cohen, Rob" },
  { value: "Jaume Collet-Serra", label: "Collet-Serra, Jaume" },
  { value: "Chris Columbus", label: "Columbus, Chris" },
  { value: "Bill Condon", label: "Condon, Bill" },
  { value: "Frank Coraci", label: "Coraci, Frank" },
  { value: "Wes Craven", label: "Craven, Wes" },
  { value: "David Cronenberg", label: "Cronenberg, David" },
  { value: "Cameron Crowe", label: "Crowe, Cameron" },
  { value: "Alfonso Cuarón", label: "Cuarón, Alfonso" },
  { value: "Malcolm D. Lee", label: "D. Lee, Malcolm" },
  { value: "Brian De Palma", label: "De Palma, Brian" },
  { value: "Claire Denis", label: "Denis, Claire" },
  { value: "Scott Derrickson", label: "Derrickson, Scott" },
  { value: "Howard Deutch", label: "Deutch, Howard" },
  { value: "Roger Donaldson", label: "Donaldson, Roger" },
  { value: "Richard Donner", label: "Donner, Richard" },
  { value: "Dennis Dugan", label: "Dugan, Dennis" },
  { value: "Duwayne Dunham", label: "Dunham, Duwayne" },
  { value: "Clint Eastwood", label: "Eastwood, Clint" },
  { value: "Roland Emmerich", label: "Emmerich, Roland" },
  {
    value: "Bobbytrue Farrelly & Peter Farrelly",
    label: "Farrelly & Peter Farrelly, Bobbytrue",
  },
  { value: "Jon Favreau", label: "Favreau, Jon" },
  { value: "Paul Feig", label: "Feig, Paul" },
  { value: "Federico Fellini", label: "Fellini, Federico" },
  { value: "Andy Fickman", label: "Fickman, Andy" },
  { value: "David Fincher", label: "Fincher, David" },
  { value: "Francis Ford Coppola", label: "Ford Coppola, Francis" },
  { value: "Marc Forster", label: "Forster, Marc" },
  { value: "Stephen Frears", label: "Frears, Stephen" },
  { value: "William Friedkin", label: "Friedkin, William" },
  { value: "Antoine Fuqua", label: "Fuqua, Antoine" },
  { value: "John G. Avildsen", label: "G. Avildsen, John" },
  { value: "F. Gary Gray", label: "Gary Gray, F." },
  { value: "Craig Gillespie", label: "Gillespie, Craig" },
  { value: "Terry Gilliam", label: "Gilliam, Terry" },
  { value: "Will Gluck", label: "Gluck, Will" },
  { value: "Jean-Luc Godard", label: "Godard, Jean-Luc" },
  { value: "David Gordon Green", label: "Gordon Green, David" },
  { value: "Raja Gosnell", label: "Gosnell, Raja" },
  { value: "Paul Greengrass", label: "Greengrass, Paul" },
  { value: "Lasse Hallström", label: "Hallström, Lasse" },
  { value: "Michael Haneke", label: "Haneke, Michael" },
  { value: "Renny Harlin", label: "Harlin, Renny" },
  { value: "Todd Haynes", label: "Haynes, Todd" },
  { value: "Stephen Herek", label: "Herek, Stephen" },
  { value: "Alfred Hitchcock", label: "Hitchcock, Alfred" },
  { value: "Ishirō Honda", label: "Honda, Ishirō" },
  { value: "Ron Howard", label: "Howard, Ron" },
  { value: "John Hughes", label: "Hughes, John" },
  { value: "Alan J. Pakula", label: "J. Pakula, Alan" },
  { value: "Peter Jackson", label: "Jackson, Peter" },
  { value: "Jim Jarmusch", label: "Jarmusch, Jim" },
  { value: "Norman Jewison", label: "Jewison, Norman" },
  { value: "Joe Johnston", label: "Johnston, Joe" },
  { value: "Bong Joon-ho", label: "Joon-ho, Bong" },
  { value: "Wong Kar-wai", label: "Kar-wai, Wong" },
  { value: "Aki Kaurismäki", label: "Kaurismäki, Aki" },
  { value: "Abbas Kiarostami", label: "Kiarostami, Abbas" },
  { value: "Takeshi Kitano", label: "Kitano, Takeshi" },
  { value: "Stanley Kubrick", label: "Kubrick, Stanley" },
  { value: "Roger Kumble", label: "Kumble, Roger" },
  { value: "Akira Kurosawa", label: "Kurosawa, Akira" },
  { value: "John Landis", label: "Landis, John" },
  { value: "Francis Lawrence", label: "Lawrence, Francis" },
  { value: "David Lean", label: "Lean, David" },
  { value: "John Lee Hancock", label: "Lee Hancock, John" },
  { value: "Ang Lee", label: "Lee, Ang" },
  { value: "Spike Lee", label: "Lee, Spike" },
  { value: "Mike Leigh", label: "Leigh, Mike" },
  { value: "Brian Levant", label: "Levant, Brian" },
  { value: "Jonathan Levine", label: "Levine, Jonathan" },
  { value: "Barry Levinson", label: "Levinson, Barry" },
  { value: "Shawn Levy", label: "Levy, Shawn" },
  { value: "Doug Liman", label: "Liman, Doug" },
  { value: "Justin Lin", label: "Lin, Justin" },
  { value: "Richard Linklater", label: "Linklater, Richard" },
  { value: "Sam Liu", label: "Liu, Sam" },
  {
    value: "Philtrue Lord & Christopher Miller",
    label: "Lord & Christopher Miller, Philtrue",
  },
  { value: "Sidney Lumet", label: "Lumet, Sidney" },
  { value: "David Lynch", label: "Lynch, David" },
  { value: "Adrian Lyne", label: "Lyne, Adrian" },
  { value: "Jon M. Chu", label: "M. Chu, Jon" },
  { value: "John Madden", label: "Madden, John" },
  { value: "Terrence Malick", label: "Malick, Terrence" },
  { value: "Louis Malle", label: "Malle, Louis" },
  { value: "James Mangold", label: "Mangold, James" },
  { value: "Michael Mann", label: "Mann, Michael" },
  { value: "Garry Marshall", label: "Marshall, Garry" },
  { value: "Rob Marshall", label: "Marshall, Rob" },
  { value: "Tom McCarthy", label: "McCarthy, Tom" },
  { value: " McG", label: "McG, " },
  { value: "Adam McKay", label: "McKay, Adam" },
  { value: "John McTiernan", label: "McTiernan, John" },
  { value: "Jean-Pierre Melville", label: "Melville, Jean-Pierre" },
  { value: "Sam Mendes", label: "Mendes, Sam" },
  { value: "George Miller", label: "Miller, George" },
  { value: "Rob Minkoff", label: "Minkoff, Rob" },
  { value: "Mike Mitchell", label: "Mitchell, Mike" },
  { value: "Hayao Miyazaki", label: "Miyazaki, Hayao" },
  { value: "Mike Newell", label: "Newell, Mike" },
  { value: "M. Night Shyamalan", label: "Night Shyamalan, M." },
  { value: "Christopher Nolan", label: "Nolan, Christopher" },
  { value: "Phillip Noyce", label: "Noyce, Phillip" },
  { value: "Jay Oliva", label: "Oliva, Jay" },
  { value: "Frank Oz", label: "Oz, Frank" },
  { value: "Tyler Perry", label: "Perry, Tyler" },
  { value: "Todd Phillips", label: "Phillips, Todd" },
  { value: "Roman Polanski", label: "Polanski, Roman" },
  { value: "Sydney Pollack", label: "Pollack, Sydney" },
  { value: "Michael Powell", label: "Powell, Michael" },
  { value: "Sam Raimi", label: "Raimi, Sam" },
  { value: "Harold Ramis", label: "Ramis, Harold" },
  { value: "Brett Ratner", label: "Ratner, Brett" },
  { value: "Satyajit Ray", label: "Ray, Satyajit" },
  { value: "Rob Reiner", label: "Reiner, Rob" },
  { value: "Ivan Reitman", label: "Reitman, Ivan" },
  { value: "Jason Reitman", label: "Reitman, Jason" },
  { value: "Jean Renoir", label: "Renoir, Jean" },
  { value: "Guy Ritchie", label: "Ritchie, Guy" },
  { value: "Jay Roach", label: "Roach, Jay" },
  { value: "Brian Robbins", label: "Robbins, Brian" },
  { value: "Robert Rodriguez", label: "Rodriguez, Robert" },
  { value: "Nicolas Roeg", label: "Roeg, Nicolas" },
  { value: "Éric Rohmer", label: "Rohmer, Éric" },
  { value: "Roberto Rossellini", label: "Rossellini, Roberto" },
  {
    value: "Anthonytrue Russo & Joe Russo",
    label: "Russo & Joe Russo, Anthonytrue",
  },
  { value: "Joel Schumacher", label: "Schumacher, Joel" },
  { value: "Robert Schwentke", label: "Schwentke, Robert" },
  { value: "Martin Scorsese", label: "Scorsese, Martin" },
  { value: "Ridley Scott", label: "Scott, Ridley" },
  { value: "Tony Scott", label: "Scott, Tony" },
  { value: "Peter Segal", label: "Segal, Peter" },
  { value: "Adam Shankman", label: "Shankman, Adam" },
  { value: "Bryan Singer", label: "Singer, Bryan" },
  { value: "Kevin Smith", label: "Smith, Kevin" },
  { value: "Zack Snyder", label: "Snyder, Zack" },
  { value: "Steven Soderbergh", label: "Soderbergh, Steven" },
  { value: "Barry Sonnenfeld", label: "Sonnenfeld, Barry" },
  { value: "Steven Spielberg", label: "Spielberg, Steven" },
  { value: "Mark Steven Johnson", label: "Steven Johnson, Mark" },
  { value: "Nicholas Stoller", label: "Stoller, Nicholas" },
  { value: "Oliver Stone", label: "Stone, Oliver" },
  { value: "Tim Story", label: "Story, Tim" },
  { value: "Lee Tamahori", label: "Tamahori, Lee" },
  { value: "Quentin Tarantino", label: "Tarantino, Quentin" },
  { value: "Andrei Tarkovsky", label: "Tarkovsky, Andrei" },
  { value: "Andy Tennant", label: "Tennant, Andy" },
  { value: "Paul Thomas Anderson", label: "Thomas Anderson, Paul" },
  { value: "François Truffaut", label: "Truffaut, François" },
  { value: "Jon Turteltaub", label: "Turteltaub, Jon" },
  { value: "Gus Van Sant", label: "Van Sant, Gus" },
  { value: "Matthew Vaughn", label: "Vaughn, Matthew" },
  { value: "Gore Verbinski", label: "Verbinski, Gore" },
  { value: "Paul Verhoeven", label: "Verhoeven, Paul" },
  { value: "Denis Villeneuve", label: "Villeneuve, Denis" },
  { value: "Paul W.S. Anderson", label: "W.S. Anderson, Paul" },
  { value: "Jeff Wadlow", label: "Wadlow, Jeff" },
  { value: "James Wan", label: "Wan, James" },
  { value: "Mark Waters", label: "Waters, Mark" },
  { value: "Orson Welles", label: "Welles, Orson" },
  { value: "John Whitesell", label: "Whitesell, John" },
  { value: "Nicolas Winding Refn", label: "Winding Refn, Nicolas" },
  { value: "John Woo", label: "Woo, John" },
  { value: "Edgar Wright", label: "Wright, Edgar" },
  { value: "Joe Wright", label: "Wright, Joe" },
  { value: "David Yates", label: "Yates, David" },
  { value: "Robert Zemeckis", label: "Zemeckis, Robert" },
  { value: "Edward Zwick", label: "Zwick, Edward" },
  { value: "Guillermo del Toro", label: "del Toro, Guillermo" },
  { value: "Josef von Sternberg", label: "von Sternberg, Josef" },
  { value: "Lars von Trier", label: "von Trier, Lars" },
];

export const genreOptions: DropdownOption[] = [
  { value: "Action", label: "Action" },
  { value: "Adventure", label: "Adventure" },
  { value: "Animation", label: "Animation" },
  { value: "Anime", label: "Anime" },
  { value: "Comedy", label: "Comedy" },
  { value: "Comic Book", label: "Comic Book" },
  { value: "Crime", label: "Crime" },
  { value: "Disaster", label: "Disaster" },
  { value: "Drama", label: "Drama" },
  { value: "Dramedy", label: "Dramedy" },
  { value: "Fantasy", label: "Fantasy" },
  { value: "Horror", label: "Horror" },
  { value: "Musical", label: "Musical" },
  { value: "Mystery", label: "Mystery" },
  { value: "RomCom", label: "RomCom" },
  { value: "Romance", label: "Romance" },
  { value: "Sci-Fi", label: "Sci-Fi" },
  { value: "Sports", label: "Sports" },
  { value: "Thriller", label: "Thriller" },
  { value: "Western", label: "Western" },
];

export const subGenreOptions: DropdownOption[] = [
  { value: "Action Adventure", label: "Action Adventure" },
  { value: "Action Comedy", label: "Action Comedy" },
  { value: "Action Thriller", label: "Action Thriller" },
  { value: "Adult Animation", label: "Adult Animation" },
  { value: "Alien Invasion", label: "Alien Invasion" },
  { value: "Apocalypse & Disaster", label: "Apocalypse & Disaster" },
  {
    value: "Assassins/Hitmen & Gunslingers",
    label: "Assassins/Hitmen & Gunslingers",
  },
  { value: "BioPic & True Story", label: "BioPic & True Story" },
  { value: "Buddies & Screwballs", label: "Buddies & Screwballs" },
  { value: "Christmas", label: "Christmas" },
  { value: "Coming-of-Age", label: "Coming-of-Age" },
  { value: "Cooking", label: "Cooking" },
  { value: "Courtroom & Legal", label: "Courtroom & Legal" },
  { value: "Crime Comedy", label: "Crime Comedy" },
  { value: "Crime Thriller", label: "Crime Thriller" },
  { value: "Dance & Music", label: "Dance & Music" },
  { value: "Dark Comedy", label: "Dark Comedy" },
  { value: "Dinosaurs", label: "Dinosaurs" },
  { value: "Disaster & Apocalypse", label: "Disaster & Apocalypse" },
  { value: "Dystopian", label: "Dystopian" },
  { value: "Erotic Romance", label: "Erotic Romance" },
  { value: "Family Comedy", label: "Family Comedy" },
  { value: "Family Dynamics", label: "Family Dynamics" },
  { value: "Fantasy Comedy", label: "Fantasy Comedy" },
  { value: "Fast Cars & Getaways", label: "Fast Cars & Getaways" },
  { value: "Gangster & Mob/Mafia", label: "Gangster & Mob/Mafia" },
  { value: "Ghosts/Demons & Possession", label: "Ghosts/Demons & Possession" },
  {
    value: "Haunted & Paranormal/Supernatural",
    label: "Haunted & Paranormal/Supernatural",
  },
  { value: "Heist", label: "Heist" },
  { value: "Hidden Camera", label: "Hidden Camera" },
  { value: "Horror Comedy", label: "Horror Comedy" },
  { value: "Horror Thriller", label: "Horror Thriller" },
  { value: "Journalism", label: "Journalism" },
  { value: "Kids & Family", label: "Kids & Family" },
  { value: "LGBTQ+", label: "LGBTQ+" },
  { value: "Mall Cop", label: "Mall Cop" },
  {
    value: "Martial Arts/Samurai & Wuxia",
    label: "Martial Arts/Samurai & Wuxia",
  },
  { value: "Mecha & Gundam", label: "Mecha & Gundam" },
  { value: "Melodrama", label: "Melodrama" },
  { value: "Monsters & Creatures", label: "Monsters & Creatures" },
  { value: "Musical", label: "Musical" },
  { value: "Mystery & Suspense", label: "Mystery & Suspense" },
  { value: "Neo-Western", label: "Neo-Western" },
  { value: "Parody & Satire/Spoof", label: "Parody & Satire/Spoof" },
  { value: "Police & Detectives", label: "Police & Detectives" },
  { value: "Political", label: "Political" },
  { value: "Prequel", label: "Prequel" },
  { value: "Psychological Drama", label: "Psychological Drama" },
  { value: "Psychological Horror", label: "Psychological Horror" },
  { value: "Psychological Thriller", label: "Psychological Thriller" },
  { value: "Raunchy & Sex Humor", label: "Raunchy & Sex Humor" },
  { value: "Reboot", label: "Reboot" },
  { value: "Religion", label: "Religion" },
  { value: "Remake", label: "Remake" },
  { value: "Road/Roadtrip & Travel", label: "Road/Roadtrip & Travel" },
  { value: "Romantic Drama", label: "Romantic Drama" },
  { value: "Sci-Fi Horror", label: "Sci-Fi Horror" },
  { value: "Sci-Fi Thriller", label: "Sci-Fi Thriller" },
  { value: "Sequel", label: "Sequel" },
  { value: "Silent Film", label: "Silent Film" },
  { value: "Slasher & Shock/Gore", label: "Slasher & Shock/Gore" },
  { value: "Social Commentary", label: "Social Commentary" },
  { value: "Space", label: "Space" },
  { value: "Spaghetti Western", label: "Spaghetti Western" },
  { value: "Spin-off", label: "Spin-off" },
  {
    value: "Spy & Secret Agents/Espionage",
    label: "Spy & Secret Agents/Espionage",
  },
  { value: "Stand-Up", label: "Stand-Up" },
  { value: "Stoner Flick", label: "Stoner Flick" },
  { value: "Sword & Sandal", label: "Sword & Sandal" },
  { value: "Teen Comedy", label: "Teen Comedy" },
  { value: "Teen Scream", label: "Teen Scream" },
  { value: "Time Travel", label: "Time Travel" },
  { value: "Vampires", label: "Vampires" },
  { value: "Video Game", label: "Video Game" },
  { value: "War", label: "War" },
  { value: "Witches & Wizards", label: "Witches & Wizards" },
  { value: "Wuxia & Martial Arts", label: "Wuxia & Martial Arts" },
  { value: "Young Adult", label: "Young Adult" },
  { value: "Zombie", label: "Zombie" },
];

const studiocompanyFeatured: DropdownOption[] = [
  { value: "20th Century Studios", label: "20th Century Studios" },
  { value: "A24", label: "A24" },
  { value: "Amazon Studios", label: "Amazon Studios" },
  { value: "Blumhouse", label: "Blumhouse" },
  { value: "Columbia Pictures", label: "Columbia Pictures" },
  { value: "DC", label: "DC" },
  { value: "Dimension Films", label: "Dimension Films" },
  { value: "Disney", label: "Disney" },
  { value: "DreamWorks Pictures", label: "DreamWorks Pictures" },
  { value: "Happy Madison", label: "Happy Madison" },
  { value: "HBO", label: "HBO" },
  { value: "Lucasfilm", label: "Lucasfilm" },
  { value: "Marvel", label: "Marvel" },
  { value: "MGM Studios", label: "MGM Studios" },
  { value: "NEON", label: "NEON" },
  { value: "Netflix", label: "Netflix" },
  { value: "Nickelodeon", label: "Nickelodeon" },
  { value: "Paramount Pictures", label: "Paramount Pictures" },
  { value: "Searchlight Pictures", label: "Searchlight Pictures" },
  { value: "Sony Pictures", label: "Sony Pictures" },
  { value: "Studio Ghibli", label: "Studio Ghibli" },
  { value: "Universal Pictures", label: "Universal Pictures" },
  { value: "Warner Bros", label: "Warner Bros" },
];

const studiocompanyOther: DropdownOption[] = [
  { value: "01 Distribution", label: "01 Distribution" },
  { value: "3-H Films", label: "3-H Films" },
  {
    value: "40 Acres and a Mule Filmworks",
    label: "40 Acres and a Mule Filmworks",
  },
  { value: "87Eleven Productions", label: "87Eleven Productions" },
  { value: "A-Pix Entertainment", label: "A-Pix Entertainment" },
  { value: "AAA Classics", label: "AAA Classics" },
  { value: "AB Svensk Filmindustri", label: "AB Svensk Filmindustri" },
  { value: "AGBO", label: "AGBO" },
  { value: "AGC Studios", label: "AGC Studios" },
  { value: "AGC Sutdios", label: "AGC Sutdios" },
  { value: "AMC Networks", label: "AMC Networks" },
  { value: "AMLF", label: "AMLF" },
  { value: "ARRAY", label: "ARRAY" },
  { value: "ATO Pictures", label: "ATO Pictures" },
  { value: "Aardman Animation", label: "Aardman Animation" },
  { value: "Absurda Inc.", label: "Absurda Inc." },
  { value: "Acme Rocket Fuel", label: "Acme Rocket Fuel" },
  { value: "Acteurs Auteurs Associés", label: "Acteurs Auteurs Associés" },
  { value: "Action Gitanes", label: "Action Gitanes" },
  { value: "Adult Swim", label: "Adult Swim" },
  { value: "Affirm", label: "Affirm" },
  { value: "Alibaba Pictures", label: "Alibaba Pictures" },
  { value: "Alive Films", label: "Alive Films" },
  { value: "Alliance Films", label: "Alliance Films" },
  { value: "Allied Artists Pictures", label: "Allied Artists Pictures" },
  { value: "Almi Pictures", label: "Almi Pictures" },
  { value: "Amazing Incorporated", label: "Amazing Incorporated" },
  { value: "Amblin", label: "Amblin" },
  {
    value: "American International Pictures",
    label: "American International Pictures",
  },
  { value: "Amplify", label: "Amplify" },
  { value: "Anchor Bay Entertainment", label: "Anchor Bay Entertainment" },
  {
    value: "Anglo-Amalgamated Productions",
    label: "Anglo-Amalgamated Productions",
  },
  { value: "Annapurna Pictures", label: "Annapurna Pictures" },
  { value: "Apatow Productions", label: "Apatow Productions" },
  { value: "Apparition", label: "Apparition" },
  { value: "Apple Studios", label: "Apple Studios" },
  { value: "Argos Films", label: "Argos Films" },
  { value: "Aries Films", label: "Aries Films" },
  { value: "Armenfilm", label: "Armenfilm" },
  { value: "Arrival Pictures", label: "Arrival Pictures" },
  { value: "Arte France Cinema", label: "Arte France Cinema" },
  { value: "Artificial Eye", label: "Artificial Eye" },
  { value: "Artisan Entertainment", label: "Artisan Entertainment" },
  { value: "Artistic License", label: "Artistic License" },
  { value: "Astor Pictures", label: "Astor Pictures" },
  { value: "Athos Films", label: "Athos Films" },
  { value: "Atlas Entertainment", label: "Atlas Entertainment" },
  { value: "Audifilm", label: "Audifilm" },
  { value: "Avalanche Animation", label: "Avalanche Animation" },
  { value: "Avenue Entertainment", label: "Avenue Entertainment" },
  { value: "Aviron Pictures", label: "Aviron Pictures" },
  { value: "Axiom Films", label: "Axiom Films" },
  {
    value: "B.O.M. Film Productions Co.",
    label: "B.O.M. Film Productions Co.",
  },
  { value: "BAC Films", label: "BAC Films" },
  { value: "BBC Films", label: "BBC Films" },
  { value: "BRON", label: "BRON" },
  { value: "Bad Robot", label: "Bad Robot" },
  { value: "Balboa Productions", label: "Balboa Productions" },
  { value: "Bandai Entertainment", label: "Bandai Entertainment" },
  { value: "Barrandov Studios", label: "Barrandov Studios" },
  { value: "Beijing New Picture Films", label: "Beijing New Picture Films" },
  { value: "Bitter Films", label: "Bitter Films" },
  { value: "Black Bear Pictures", label: "Black Bear Pictures" },
  { value: "Black Diamond Pictures", label: "Black Diamond Pictures" },
  { value: "Bleecker Street", label: "Bleecker Street" },
  { value: "Blinder Films", label: "Blinder Films" },
  { value: "Bloody Disgusting", label: "Bloody Disgusting" },
  { value: "Blue Fox Entertainment", label: "Blue Fox Entertainment" },
  { value: "Blue Sky Animation", label: "Blue Sky Animation" },
  { value: "Bluefields Entertainment", label: "Bluefields Entertainment" },
  { value: "Bocaccio Distribución", label: "Bocaccio Distribución" },
  { value: "Bones", label: "Bones" },
  { value: "Boo Productions", label: "Boo Productions" },
  { value: "Bounty Films", label: "Bounty Films" },
  { value: "Brainstorm media", label: "Brainstorm media" },
  { value: "British Lion Films", label: "British Lion Films" },
  { value: "Broad Green Pictures", label: "Broad Green Pictures" },
  { value: "Bryanston Pictures", label: "Bryanston Pictures" },
  { value: "Buena Vista Pictures", label: "Buena Vista Pictures" },
  { value: "BuzzFeed Studios", label: "BuzzFeed Studios" },
  { value: "CBS Films", label: "CBS Films" },
  { value: "CG Cinema", label: "CG Cinema" },
  { value: "CGV Arthouse", label: "CGV Arthouse" },
  { value: "CJ Entertainment", label: "CJ Entertainment" },
  { value: "Canal+", label: "Canal+" },
  { value: "Cannon Films", label: "Cannon Films" },
  { value: "Cartoon Saloon", label: "Cartoon Saloon" },
  { value: "Castle Rock Entertainment", label: "Castle Rock Entertainment" },
  { value: "Celluoid Dreams", label: "Celluoid Dreams" },
  { value: "Central Motion Pictures", label: "Central Motion Pictures" },
  { value: "Channel 4 Films", label: "Channel 4 Films" },
  {
    value: "Charlie Chaplin Productions",
    label: "Charlie Chaplin Productions",
  },
  { value: "Chernin", label: "Chernin" },
  { value: "Chicago Releasing", label: "Chicago Releasing" },
  { value: "China Film Group", label: "China Film Group" },
  {
    value: "China Star Entertainment Group",
    label: "China Star Entertainment Group",
  },
  { value: "Cine Qua Non Films", label: "Cine Qua Non Films" },
  { value: "Cine-Source", label: "Cine-Source" },
  { value: "CineRocket", label: "CineRocket" },
  { value: "Cinedigm", label: "Cinedigm" },
  { value: "Cinedis", label: "Cinedis" },
  { value: "Cinema 5", label: "Cinema 5" },
  { value: "Cinema Guild", label: "Cinema Guild" },
  { value: "Cinema Ventures", label: "Cinema Ventures" },
  { value: "CinemaScópio", label: "CinemaScópio" },
  { value: "Cineplex-Odeon Films", label: "Cineplex-Odeon Films" },
  {
    value: "Cinerama Releasing Corporation",
    label: "Cinerama Releasing Corporation",
  },
  { value: "Cineriz", label: "Cineriz" },
  { value: "Cinevista", label: "Cinevista" },
  { value: "CoMix Wave Films", label: "CoMix Wave Films" },
  { value: "Cocinor", label: "Cocinor" },
  { value: "CollegeHumor", label: "CollegeHumor" },
  {
    value: "Compagnie Française de Distribution",
    label: "Compagnie Française de Distribution",
  },
  {
    value: "Compass International Pictures",
    label: "Compass International Pictures",
  },
  { value: "Compton Films", label: "Compton Films" },
  { value: "Constantin Film", label: "Constantin Film" },
  { value: "Continental Distributing", label: "Continental Distributing" },
  { value: "Convoy Entertainment", label: "Convoy Entertainment" },
  { value: "Cowboy Pictures", label: "Cowboy Pictures" },
  { value: "Crane Movie Company", label: "Crane Movie Company" },
  { value: "Crest Animation", label: "Crest Animation" },
  { value: "Crook Brothers Films", label: "Crook Brothers Films" },
  { value: "Crossbow Productions", label: "Crossbow Productions" },
  { value: "Curiosa Films", label: "Curiosa Films" },
  { value: "DUST", label: "DUST" },
  { value: "Dadas Film", label: "Dadas Film" },
  { value: "Daiei Film", label: "Daiei Film" },
  { value: "Daily Wire", label: "Daily Wire" },
  { value: "Dark Horse", label: "Dark Horse" },
  { value: "Dark Sky Films", label: "Dark Sky Films" },
  {
    value: "De Laurentiis Entertainment Group",
    label: "De Laurentiis Entertainment Group",
  },
  { value: "Decal", label: "Decal" },
  { value: "Destination Films", label: "Destination Films" },
  { value: "Det Danske Filminstitut", label: "Det Danske Filminstitut" },
  { value: "Development Ltd.", label: "Development Ltd." },
  { value: "Diaphana Films", label: "Diaphana Films" },
  { value: "Digital Thunderdome", label: "Digital Thunderdome" },
  { value: "DisCina", label: "DisCina" },
  { value: "Drafthouse Films", label: "Drafthouse Films" },
  { value: "DreamWorks Animation", label: "DreamWorks Animation" },
  {
    value: "Duplass Brothers Productions",
    label: "Duplass Brothers Productions",
  },
  { value: "ESPN", label: "ESPN" },
  { value: "ESX Entertainment", label: "ESX Entertainment" },
  { value: "Eagle-Lion Films", label: "Eagle-Lion Films" },
  { value: "Echo Bridge Entertainment", label: "Echo Bridge Entertainment" },
  { value: "El Deseo", label: "El Deseo" },
  { value: "Electric Entertainment", label: "Electric Entertainment" },
  { value: "Element Pictures", label: "Element Pictures" },
  { value: "Embassy Pictures", label: "Embassy Pictures" },
  { value: "Emmet/Furla Oasis Films", label: "Emmet/Furla Oasis Films" },
  { value: "Emmett/Furla Oasis Films", label: "Emmett/Furla Oasis Films" },
  {
    value: "Empire International Pictures",
    label: "Empire International Pictures",
  },
  { value: "Enbu Seminar", label: "Enbu Seminar" },
  { value: "Endeavor Productions", label: "Endeavor Productions" },
  { value: "Endeavour Productions", label: "Endeavour Productions" },
  { value: "Endgame Entertainment", label: "Endgame Entertainment" },
  {
    value: "Ente Nazionale Industrie Cinematografiche",
    label: "Ente Nazionale Industrie Cinematografiche",
  },
  { value: "Entertainment One", label: "Entertainment One" },
  { value: "Epic Pictures", label: "Epic Pictures" },
  { value: "Epic Pictures Group", label: "Epic Pictures Group" },
  { value: "Euro International Films", label: "Euro International Films" },
  { value: "EuropaCorp", label: "EuropaCorp" },
  { value: "Faces International Films", label: "Faces International Films" },
  { value: "Fandango", label: "Fandango" },
  {
    value: "Fantastic Films International",
    label: "Fantastic Films International",
  },
  { value: "Fathom Events", label: "Fathom Events" },
  { value: "Fbi Films", label: "Fbi Films" },
  { value: "Film Arcade", label: "Film Arcade" },
  { value: "Film Arts Guild", label: "Film Arts Guild" },
  { value: "Film Produkcja", label: "Film Produkcja" },
  { value: "Film Workshop", label: "Film Workshop" },
  { value: "Film4", label: "Film4" },
  { value: "FilmDistrict", label: "FilmDistrict" },
  { value: "FilmRise", label: "FilmRise" },
  { value: "Filmax", label: "Filmax" },
  { value: "Filmel", label: "Filmel" },
  {
    value: "Films Georges de Beauregard",
    label: "Films Georges de Beauregard",
  },
  { value: "Filmways Pictures", label: "Filmways Pictures" },
  { value: "Fine Line Features", label: "Fine Line Features" },
  { value: "Finnkino", label: "Finnkino" },
  { value: "First Independent Films", label: "First Independent Films" },
  { value: "First Look Studios", label: "First Look Studios" },
  { value: "First National", label: "First National" },
  { value: "First Run Features", label: "First Run Features" },
  { value: "Five Star Production", label: "Five Star Production" },
  { value: "Focus Features", label: "Focus Features" },
  { value: "Focus World", label: "Focus World" },
  { value: "Fox Lorber", label: "Fox Lorber" },
  { value: "Franchise Pictures", label: "Franchise Pictures" },
  { value: "Freestyle Releasing", label: "Freestyle Releasing" },
  { value: "Full Circle Releasing", label: "Full Circle Releasing" },
  { value: "Fundamental Films", label: "Fundamental Films" },
  { value: "GAGA Pictures", label: "GAGA Pictures" },
  { value: "Galaxy Releasing", label: "Galaxy Releasing" },
  { value: "Gamechanger Films", label: "Gamechanger Films" },
  { value: "Gamma Film", label: "Gamma Film" },
  { value: "Gary Sanchez", label: "Gary Sanchez" },
  { value: "Gaumont Film Company", label: "Gaumont Film Company" },
  { value: "General Film Distributors", label: "General Film Distributors" },
  { value: "Global Road Entertainment", label: "Global Road Entertainment" },
  { value: "Gloria Sanchez", label: "Gloria Sanchez" },
  { value: "GoDigital", label: "GoDigital" },
  { value: "Golden Harvest", label: "Golden Harvest" },
  {
    value: "Golden Princess Film Production",
    label: "Golden Princess Film Production",
  },
  { value: "Goldrush Entertainment", label: "Goldrush Entertainment" },
  { value: "Good Deed Entertainment", label: "Good Deed Entertainment" },
  { value: "Gramercy Pictures", label: "Gramercy Pictures" },
  { value: "Granada Television", label: "Granada Television" },
  { value: "Gravitas Ventures", label: "Gravitas Ventures" },
  { value: "Greycat Films", label: "Greycat Films" },
  { value: "Grindstone Entertainment", label: "Grindstone Entertainment" },
  { value: "Gunpowder & Sky", label: "Gunpowder & Sky" },
  { value: "Gustavo Alatriste", label: "Gustavo Alatriste" },
  { value: "Hallmark", label: "Hallmark" },
  { value: "HandMade Films", label: "HandMade Films" },
  { value: "Hayhoe Studios", label: "Hayhoe Studios" },
  { value: "Helsinki-filmi", label: "Helsinki-filmi" },
  { value: "Hemdale Film Corporation", label: "Hemdale Film Corporation" },
  { value: "High Top Releasing", label: "High Top Releasing" },
  { value: "Horror Collective", label: "Horror Collective" },
  { value: "Huhu Animation", label: "Huhu Animation" },
  { value: "Hulu", label: "Hulu" },
  { value: "Hyperion Pictures", label: "Hyperion Pictures" },
  { value: "Hyperobject Industries", label: "Hyperobject Industries" },
  { value: "I.R.S. Releasing", label: "I.R.S. Releasing" },
  { value: "IFC Films", label: "IFC Films" },
  { value: "Icon Film Distribution", label: "Icon Film Distribution" },
  { value: "Illumination", label: "Illumination" },
  { value: "Imagine Entertainment", label: "Imagine Entertainment" },
  { value: "Imamura Productions", label: "Imamura Productions" },
  { value: "In-Gear Films", label: "In-Gear Films" },
  { value: "Indomina Releasing", label: "Indomina Releasing" },
  { value: "Ingenious Media", label: "Ingenious Media" },
  { value: "Invincible Pictures", label: "Invincible Pictures" },
  { value: "Irish Film Board", label: "Irish Film Board" },
  { value: "JTJ Films", label: "JTJ Films" },
  { value: "Janus Films", label: "Janus Films" },
  { value: "Jerry Bruckheimer Films", label: "Jerry Bruckheimer Films" },
  { value: "Jet Tone Productions", label: "Jet Tone Productions" },
  { value: "Jhumka Films", label: "Jhumka Films" },
  { value: "Jinga Films", label: "Jinga Films" },
  { value: "Jolly Film", label: "Jolly Film" },
  { value: "Joseph Burstyn Inc.", label: "Joseph Burstyn Inc." },
  { value: "Juno Films", label: "Juno Films" },
  { value: "KADR", label: "KADR" },
  { value: "Kadokawa Herald Pictures", label: "Kadokawa Herald Pictures" },
  { value: "Kaijyu Theatres", label: "Kaijyu Theatres" },
  { value: "Kanoon", label: "Kanoon" },
  { value: "Karbo Vantas Entertainment", label: "Karbo Vantas Entertainment" },
  { value: "Kartuli Pilmi", label: "Kartuli Pilmi" },
  { value: "Keystone Pictures", label: "Keystone Pictures" },
  { value: "Kick the Machine", label: "Kick the Machine" },
  { value: "Killer Films", label: "Killer Films" },
  { value: "Kingdom Story Company", label: "Kingdom Story Company" },
  { value: "Kino International", label: "Kino International" },
  { value: "Kirk Cameron Entertainment", label: "Kirk Cameron Entertainment" },
  { value: "Koch-Lorber Films", label: "Koch-Lorber Films" },
  { value: "Kuzui Enterprises", label: "Kuzui Enterprises" },
  { value: "LD Entertainment", label: "LD Entertainment" },
  { value: "Laika Animation", label: "Laika Animation" },
  { value: "Lakeshore Entertainment", label: "Lakeshore Entertainment" },
  { value: "Lantern Entertainment", label: "Lantern Entertainment" },
  {
    value: "Las Producciones del Escorpión",
    label: "Las Producciones del Escorpión",
  },
  { value: "Lauren Films", label: "Lauren Films" },
  { value: "Lee Mendelson Films", label: "Lee Mendelson Films" },
  { value: "Legacy Releasing", label: "Legacy Releasing" },
  { value: "Legendary Pictures", label: "Legendary Pictures" },
  { value: "Lenfilm", label: "Lenfilm" },
  { value: "Les Films Corona", label: "Les Films Corona" },
  { value: "Les Films Impéria", label: "Les Films Impéria" },
  { value: "Les Films Marceau", label: "Les Films Marceau" },
  { value: "Les Films du Fleuve", label: "Les Films du Fleuve" },
  { value: "Les Films du Losange", label: "Les Films du Losange" },
  { value: "Libra Films", label: "Libra Films" },
  { value: "Lider Films", label: "Lider Films" },
  { value: "Lightstorm Entertainment", label: "Lightstorm Entertainment" },
  { value: "Lionsgate", label: "Lionsgate" },
  { value: "Lippert Pictures", label: "Lippert Pictures" },
  { value: "Lopert Pictures", label: "Lopert Pictures" },
  { value: "Lux Film", label: "Lux Film" },
  { value: "MK Productions", label: "MK Productions" },
  { value: "MK2 Diffusion", label: "MK2 Diffusion" },
  { value: "MPI Media Group", label: "MPI Media Group" },
  { value: "MTV Films", label: "MTV Films" },
  { value: "MUBI", label: "MUBI" },
  { value: "Madhouse", label: "Madhouse" },
  { value: "Madman Films", label: "Madman Films" },
  { value: "Magellan Pictures", label: "Magellan Pictures" },
  { value: "Magnet Releasing", label: "Magnet Releasing" },
  { value: "Magnolia Pictures", label: "Magnolia Pictures" },
  { value: "Mailine Pictures", label: "Mailine Pictures" },
  { value: "Mandarin Films", label: "Mandarin Films" },
  { value: "Manga Entertainment", label: "Manga Entertainment" },
  { value: "Mars Films", label: "Mars Films" },
  { value: "Marv Films", label: "Marv Films" },
  { value: "Maven Screen Media", label: "Maven Screen Media" },
  { value: "Media Blasters", label: "Media Blasters" },
  { value: "Medusa Film", label: "Medusa Film" },
  { value: "Memento Films", label: "Memento Films" },
  { value: "Mercury Film Group", label: "Mercury Film Group" },
  { value: "Milestone Films", label: "Milestone Films" },
  { value: "Millennium Films", label: "Millennium Films" },
  { value: "Mippei Eiga Kiryūkan", label: "Mippei Eiga Kiryūkan" },
  { value: "Miramax", label: "Miramax" },
  { value: "Miramax Films", label: "Miramax Films" },
  { value: "Momentum Pictures", label: "Momentum Pictures" },
  { value: "Monkeypaw Productions", label: "Monkeypaw Productions" },
  { value: "Mosfilm", label: "Mosfilm" },
  { value: "MoviePass Ventures", label: "MoviePass Ventures" },
  { value: "Mushroom Pictures", label: "Mushroom Pictures" },
  { value: "Music Box Films", label: "Music Box Films" },
  { value: "NDM", label: "NDM" },
  { value: "Nelson Entertainment", label: "Nelson Entertainment" },
  { value: "NeoClassics Films", label: "NeoClassics Films" },
  { value: "Nero-Film A.G.", label: "Nero-Film A.G." },
  { value: "New Artist Pictures", label: "New Artist Pictures" },
  { value: "New Line Cinema", label: "New Line Cinema" },
  { value: "New World Pictures", label: "New World Pictures" },
  { value: "New Yorker Films", label: "New Yorker Films" },
  { value: "Newmarket Films", label: "Newmarket Films" },
  { value: "Next Entertainment World", label: "Next Entertainment World" },
  { value: "Nickelodeon Movies", label: "Nickelodeon Movies" },
  { value: "Nikkatsu", label: "Nikkatsu" },
  { value: "Nimbus Film", label: "Nimbus Film" },
  { value: "Nimbus Films", label: "Nimbus Films" },
  { value: "Nintendo", label: "Nintendo" },
  { value: "Nordisk Film", label: "Nordisk Film" },
  { value: "Northern Arts", label: "Northern Arts" },
  { value: "October Films", label: "October Films" },
  { value: "Office Kitano", label: "Office Kitano" },
  { value: "Open Road Films", label: "Open Road Films" },
  { value: "Optimum Releasing", label: "Optimum Releasing" },
  { value: "Orchard", label: "Orchard" },
  { value: "Orion Pictures", label: "Orion Pictures" },
  { value: "Oro Films", label: "Oro Films" },
  { value: "Oscilloscope Laboratories", label: "Oscilloscope Laboratories" },
  { value: "Overture Films", label: "Overture Films" },
  { value: "Palace Pictures", label: "Palace Pictures" },
  { value: "Paladin", label: "Paladin" },
  { value: "Palladium", label: "Palladium" },
  { value: "Palm Pictures", label: "Palm Pictures" },
  { value: "Pandora Cinema", label: "Pandora Cinema" },
  { value: "Pantelion Films", label: "Pantelion Films" },
  { value: "Panthéon Distribution", label: "Panthéon Distribution" },
  { value: "Paper Kite Productions", label: "Paper Kite Productions" },
  { value: "Parafrance", label: "Parafrance" },
  { value: "Pathé", label: "Pathé" },
  { value: "Peace Arch Entertainment", label: "Peace Arch Entertainment" },
  { value: "Peacock", label: "Peacock" },
  { value: "Picturehouse Entertainment", label: "Picturehouse Entertainment" },
  { value: "Pierre Grise Productions", label: "Pierre Grise Productions" },
  { value: "Piki Films", label: "Piki Films" },
  { value: "Plan B Entertainment", label: "Plan B Entertainment" },
  { value: "Planfilm", label: "Planfilm" },
  { value: "Platinum Dunes", label: "Platinum Dunes" },
  { value: "PlayStation Productions", label: "PlayStation Productions" },
  { value: "Playboy Productions", label: "Playboy Productions" },
  {
    value: "PolyGram Filmed Entertainment",
    label: "PolyGram Filmed Entertainment",
  },
  { value: "Prestige", label: "Prestige" },
  { value: "Pretorius Pictures", label: "Pretorius Pictures" },
  { value: "Président Films", label: "Président Films" },
  { value: "Quiver Distribution", label: "Quiver Distribution" },
  { value: "RADiUS-TWC", label: "RADiUS-TWC" },
  { value: "RAF Pictures", label: "RAF Pictures" },
  { value: "RAI Cinema", label: "RAI Cinema" },
  { value: "RCR Distribution", label: "RCR Distribution" },
  { value: "RKO Radio Pictures", label: "RKO Radio Pictures" },
  { value: "RLJ Entertainment", label: "RLJ Entertainment" },
  { value: "RUSCICO", label: "RUSCICO" },
  { value: "Rainmaker Animation", label: "Rainmaker Animation" },
  { value: "Rankin/Bass Productions", label: "Rankin/Bass Productions" },
  { value: "Recorded Picture Company", label: "Recorded Picture Company" },
  { value: "Redbox Entertainment", label: "Redbox Entertainment" },
  { value: "Reel FX Animation", label: "Reel FX Animation" },
  { value: "Regatta", label: "Regatta" },
  { value: "Relativity Media", label: "Relativity Media" },
  { value: "Renaissance Pictures", label: "Renaissance Pictures" },
  { value: "Republic Pictures", label: "Republic Pictures" },
  { value: "Resonate Entertainment", label: "Resonate Entertainment" },
  { value: "Rialto Pictures", label: "Rialto Pictures" },
  { value: "Rivdel Films", label: "Rivdel Films" },
  { value: "River Road", label: "River Road" },
  { value: "Rizzoli", label: "Rizzoli" },
  { value: "Roadshow Entertainment", label: "Roadshow Entertainment" },
  { value: "Roadside Attractions", label: "Roadside Attractions" },
  { value: "Rogue Pictures", label: "Rogue Pictures" },
  { value: "Roku Channel", label: "Roku Channel" },
  { value: "Rose & Ruby", label: "Rose & Ruby" },
  { value: "Rosebud Communications", label: "Rosebud Communications" },
  { value: "SRS Cinema", label: "SRS Cinema" },
  { value: "STX Films", label: "STX Films" },
  { value: "Saban Films", label: "Saban Films" },
  { value: "Safran Company", label: "Safran Company" },
  { value: "Samuel Goldwyn Films", label: "Samuel Goldwyn Films" },
  { value: "Sands Films", label: "Sands Films" },
  { value: "Sara Films", label: "Sara Films" },
  { value: "Saturn Films", label: "Saturn Films" },
  { value: "Savoy Pictures", label: "Savoy Pictures" },
  { value: "Schlaaang", label: "Schlaaang" },
  { value: "Scholastic Entertainment", label: "Scholastic Entertainment" },
  { value: "Screen Gems", label: "Screen Gems" },
  { value: "Screen Guild Productions", label: "Screen Guild Productions" },
  { value: "Screen Media Films", label: "Screen Media Films" },
  { value: "Seattle Classic Films", label: "Seattle Classic Films" },
  { value: "See-Saw Films", label: "See-Saw Films" },
  { value: "Senator Film", label: "Senator Film" },
  { value: "Seven Bucks Productions", label: "Seven Bucks Productions" },
  { value: "Shining Excalibur Films", label: "Shining Excalibur Films" },
  { value: "Shintoho", label: "Shintoho" },
  { value: "Shochiku", label: "Shochiku" },
  { value: "Shout! Studios", label: "Shout! Studios" },
  { value: "Showbox Entertainment", label: "Showbox Entertainment" },
  { value: "Showtime Networks", label: "Showtime Networks" },
  { value: "Shudder", label: "Shudder" },
  { value: "Signature Entertainment", label: "Signature Entertainment" },
  { value: "Skouras Films", label: "Skouras Films" },
  { value: "Skydance", label: "Skydance" },
  { value: "Solstice Studios", label: "Solstice Studios" },
  { value: "Sovexportfilm", label: "Sovexportfilm" },
  { value: "Specta Films", label: "Specta Films" },
  { value: "Sputnik", label: "Sputnik" },
  { value: "Stage 6 Films", label: "Stage 6 Films" },
  { value: "Star Film Company", label: "Star Film Company" },
  { value: "Starz Animation", label: "Starz Animation" },
  { value: "Strand Releasing", label: "Strand Releasing" },
  { value: "Studio Deen", label: "Studio Deen" },
  { value: "StudioCanal", label: "StudioCanal" },
  { value: "Summit Entertainment", label: "Summit Entertainment" },
  { value: "Sundance Institute", label: "Sundance Institute" },
  { value: "Sweetland Films", label: "Sweetland Films" },
  { value: "Syfy Films", label: "Syfy Films" },
  { value: "Syncopy", label: "Syncopy" },
  { value: "THINKFilm", label: "THINKFilm" },
  { value: "TRT", label: "TRT" },
  { value: "TV Man Union", label: "TV Man Union" },
  { value: "Tango-Film", label: "Tango-Film" },
  { value: "Tartan Films", label: "Tartan Films" },
  { value: "Telefe Productions", label: "Telefe Productions" },
  { value: "Third Rail Releasing", label: "Third Rail Releasing" },
  { value: "Thunder Road Films", label: "Thunder Road Films" },
  { value: "Titanus", label: "Titanus" },
  { value: "Toei Company", label: "Toei Company" },
  { value: "Toho", label: "Toho" },
  { value: "Tokyo Movie Shinsha", label: "Tokyo Movie Shinsha" },
  { value: "Topcraft", label: "Topcraft" },
  { value: "Touchstone Pictures", label: "Touchstone Pictures" },
  { value: "Trans World Entertainment", label: "Trans World Entertainment" },
  { value: "TriArt Film", label: "TriArt Film" },
  { value: "TriStar Pictures", label: "TriStar Pictures" },
  { value: "Tribeca Film", label: "Tribeca Film" },
  { value: "Trimark Pictures", label: "Trimark Pictures" },
  { value: "Triumph Films", label: "Triumph Films" },
  { value: "Tubi", label: "Tubi" },
  { value: "Turtle Releasing", label: "Turtle Releasing" },
  { value: "Tyler Perry Studios", label: "Tyler Perry Studios" },
  { value: "UGC", label: "UGC" },
  { value: "UP2U Films", label: "UP2U Films" },
  { value: "USA Films", label: "USA Films" },
  { value: "Uncork'd Entertainment", label: "Uncork'd Entertainment" },
  { value: "United Artists", label: "United Artists" },
  {
    value: "United International Pictures",
    label: "United International Pictures",
  },
  { value: "Universe Entertainment", label: "Universe Entertainment" },
  { value: "Utopia Pictures", label: "Utopia Pictures" },
  { value: "VHX", label: "VHX" },
  { value: "VVS Films", label: "VVS Films" },
  { value: "Vardar Film", label: "Vardar Film" },
  { value: "Vera Films", label: "Vera Films" },
  { value: "Vertical Entertainment", label: "Vertical Entertainment" },
  { value: "Vertigo Films", label: "Vertigo Films" },
  { value: "Vestron Pictures", label: "Vestron Pictures" },
  { value: "Vinod Chopra Films", label: "Vinod Chopra Films" },
  { value: "Virgin Produced", label: "Virgin Produced" },
  { value: "Vitagraph Studios", label: "Vitagraph Studios" },
  { value: "Vitrine Filmes", label: "Vitrine Filmes" },
  { value: "Vivendi Entertainment", label: "Vivendi Entertainment" },
  { value: "Viz Pictures", label: "Viz Pictures" },
  { value: "Voltage Pictures", label: "Voltage Pictures" },
  { value: "Votiv Films", label: "Votiv Films" },
  { value: "WWE Films", label: "WWE Films" },
  { value: "Wandering Trail Pictures", label: "Wandering Trail Pictures" },
  { value: "Warner Bros", label: "Warner Bros" },
  { value: "Warner Bros,", label: "Warner Bros," },
  { value: "Well Go USA", label: "Well Go USA" },
  { value: "Wellspring Media", label: "Wellspring Media" },
  {
    value: "Werner Herzog Filmproduktion",
    label: "Werner Herzog Filmproduktion",
  },
  { value: "Wild Bunch", label: "Wild Bunch" },
  { value: "Wild Eye Releasing", label: "Wild Eye Releasing" },
  { value: "WinStar Cinema", label: "WinStar Cinema" },
  { value: "Wiseau-Films", label: "Wiseau-Films" },
  { value: "Working Title", label: "Working Title" },
  { value: "X-Filme", label: "X-Filme" },
  { value: "XYZ Films", label: "XYZ Films" },
  { value: "Xi'an Film Studio", label: "Xi'an Film Studio" },
  { value: "Xlrator Media", label: "Xlrator Media" },
  { value: "Xstream Pictures", label: "Xstream Pictures" },
  { value: "Yari Film Group", label: "Yari Film Group" },
  { value: "Zeitgeist Films", label: "Zeitgeist Films" },
  { value: "Zentropa Entertainments", label: "Zentropa Entertainments" },
  { value: "Zespol Filmowy", label: "Zespol Filmowy" },
  { value: "Zeta Entertainment", label: "Zeta Entertainment" },
];

export const studiocompanyOptions: object[] = [
  {
    label: "Featured",
    options: studiocompanyFeatured,
  },
  {
    label: "Other",
    options: studiocompanyOther,
  },
];

export const universeFeatured: DropdownOption[] = [
  { value: "A Cinderella Story Series", label: "A Cinderella Story Series" },
  { value: "A Nightmare on Elm Street", label: "A Nightmare on Elm Street" },
  { value: "AMC+ Original", label: "AMC+ Original" },
  { value: "Aaron Sorkin Universe", label: "Aaron Sorkin Universe" },
  { value: "Ace Ventura", label: "Ace Ventura" },
  { value: "Addams Family", label: "Addams Family" },
  {
    value: "Adventures of Antoine Doinel",
    label: "Adventures of Antoine Doinel",
  },
  { value: "After Series", label: "After Series" },
  { value: "Airplane!", label: "Airplane!" },
  { value: "Alien vs. Predator", label: "Alien vs. Predator" },
  { value: "Almighty Franchise", label: "Almighty Franchise" },
  { value: "Amazon Prime Original", label: "Amazon Prime Original" },
  { value: "American Pie", label: "American Pie" },
  { value: "Amityville", label: "Amityville" },
  { value: "Analyze This Series", label: "Analyze This Series" },
  { value: "Anchorman", label: "Anchorman" },
  { value: "Animated Christmas", label: "Animated Christmas" },
  { value: "Ant-Man", label: "Ant-Man" },
  { value: "Apocalypse Trilogy", label: "Apocalypse Trilogy" },
  { value: "Apple Original Films", label: "Apple Original Films" },
  { value: "Apu Trilogy", label: "Apu Trilogy" },
  { value: "Aquaman", label: "Aquaman" },
  { value: "Army of the Dead Universe", label: "Army of the Dead Universe" },
  { value: "Arthur", label: "Arthur" },
  { value: "Austin Powers", label: "Austin Powers" },
  { value: "Avatar Franchise", label: "Avatar Franchise" },
  { value: "Avengers", label: "Avengers" },
  { value: "BRD Trilogy", label: "BRD Trilogy" },
  { value: "Babe", label: "Babe" },
  { value: "Baby Geniuses", label: "Baby Geniuses" },
  { value: "Back to the Future", label: "Back to the Future" },
  { value: "Bad Boys", label: "Bad Boys" },
  { value: "Bad News Bears", label: "Bad News Bears" },
  { value: "Bad Santa Series", label: "Bad Santa Series" },
  { value: "Barbershop", label: "Barbershop" },
  { value: "Before Trilogy", label: "Before Trilogy" },
  { value: "Benson & Moorhead Universe", label: "Benson & Moorhead Universe" },
  { value: "Beverly Hills Cop", label: "Beverly Hills Cop" },
  { value: "Big Momma's House", label: "Big Momma's House" },
  { value: "Bill & Ted", label: "Bill & Ted" },
  { value: "Black Christmas Series", label: "Black Christmas Series" },
  { value: "Black Panther", label: "Black Panther" },
  { value: "Blade Runner", label: "Blade Runner" },
  { value: "Blair Witch", label: "Blair Witch" },
  { value: "Blue Sky Universe", label: "Blue Sky Universe" },
  { value: "Blues Brothers Franchise", label: "Blues Brothers Franchise" },
  { value: "Blumhouse", label: "Blumhouse" },
  { value: "Boondock Saints", label: "Boondock Saints" },
  { value: "Borat Franchise", label: "Borat Franchise" },
  { value: "Boss Baby Universe", label: "Boss Baby Universe" },
  { value: "Brady Bunch", label: "Brady Bunch" },
  { value: "Brahms: The Boy", label: "Brahms: The Boy" },
  { value: "Bridget Jones", label: "Bridget Jones" },
  { value: "Bring It On", label: "Bring It On" },
  { value: "Broken Lizard", label: "Broken Lizard" },
  {
    value: "Bruce Willis On-Demand Universe",
    label: "Bruce Willis On-Demand Universe",
  },
  { value: "Candyman Franchise", label: "Candyman Franchise" },
  { value: "Captain America", label: "Captain America" },
  { value: "Carrie Franchise", label: "Carrie Franchise" },
  { value: "Cars Trilogy", label: "Cars Trilogy" },
  { value: "Cats & Dogs Series", label: "Cats & Dogs Series" },
  { value: "Charlie Kaufman", label: "Charlie Kaufman" },
  { value: "Charlie Kaufman Series", label: "Charlie Kaufman Series" },
  { value: "Charlie's Angels", label: "Charlie's Angels" },
  {
    value: "Cheaper by the Dozen Series",
    label: "Cheaper by the Dozen Series",
  },
  { value: "Child's Play", label: "Child's Play" },
  {
    value: "Christmas Chronicles Series",
    label: "Christmas Chronicles Series",
  },
  { value: "Christmas Story Series", label: "Christmas Story Series" },
  { value: "Chronicles of Riddick", label: "Chronicles of Riddick" },
  { value: "Cloverfield", label: "Cloverfield" },
  { value: "Cloverfield Franchise", label: "Cloverfield Franchise" },
  { value: "Collector Franchise", label: "Collector Franchise" },
  {
    value: "Comedies and Proverbs (Éric Rohmer)",
    label: "Comedies and Proverbs (Éric Rohmer)",
  },
  { value: "Coming To America", label: "Coming To America" },
  { value: "Complete Jacques Tati", label: "Complete Jacques Tati" },
  { value: "Complete Jean Vigo", label: "Complete Jean Vigo" },
  { value: "Conjuring Universe", label: "Conjuring Universe" },
  { value: "Crank Franchise", label: "Crank Franchise" },
  { value: "Criterion Collection", label: "Criterion Collection" },
  { value: "Critters Franchise", label: "Critters Franchise" },
  { value: "Crocodile Dundee Films", label: "Crocodile Dundee Films" },
  { value: "Croods Universe", label: "Croods Universe" },
  { value: "Da Vinci Code Trilogy", label: "Da Vinci Code Trilogy" },
  { value: "Daddy Universe", label: "Daddy Universe" },
  { value: "Dark Universe", label: "Dark Universe" },
  {
    value: "David Lean Directs Noël Coward",
    label: "David Lean Directs Noël Coward",
  },
  { value: "Death Race", label: "Death Race" },
  { value: "Diary of a Wimpy Kid", label: "Diary of a Wimpy Kid" },
  { value: "Die Hard", label: "Die Hard" },
  {
    value: "Dietrich & von Sternberg in Hollywood",
    label: "Dietrich & von Sternberg in Hollywood",
  },
  { value: "Dirty Harry", label: "Dirty Harry" },
  { value: "Disney Theme Park Rides", label: "Disney Theme Park Rides" },
  { value: "Divergent Series", label: "Divergent Series" },
  { value: "Dollars Trilogy", label: "Dollars Trilogy" },
  { value: "Dolphin Tale Series", label: "Dolphin Tale Series" },
  { value: "Don't Breathe Series", label: "Don't Breathe Series" },
  { value: "Downton Abbey Films", label: "Downton Abbey Films" },
  { value: "Dr. Dolittle", label: "Dr. Dolittle" },
  { value: "Dr. Seuss", label: "Dr. Seuss" },
  { value: "Dragon Ball Z", label: "Dragon Ball Z" },
  { value: "Dragon Tattoo Stories", label: "Dragon Tattoo Stories" },
  { value: "Dumb and Dumber", label: "Dumb and Dumber" },
  { value: "Elite Squad", label: "Elite Squad" },
  { value: "Enchanted Franchise", label: "Enchanted Franchise" },
  { value: "Equalizer Franchise", label: "Equalizer Franchise" },
  { value: "Ernest Franchise", label: "Ernest Franchise" },
  { value: "Escape From Series", label: "Escape From Series" },
  { value: "Escape Plan Series", label: "Escape Plan Series" },
  { value: "Escape Room Series", label: "Escape Room Series" },
  { value: "Essential Jacques Demy", label: "Essential Jacques Demy" },
  { value: "Europa Trilogy", label: "Europa Trilogy" },
  { value: "Evil Dead", label: "Evil Dead" },
  { value: "Expendables Franchise", label: "Expendables Franchise" },
  { value: "Fantastic Four", label: "Fantastic Four" },
  { value: "Father & Son Series", label: "Father & Son Series" },
  { value: "Fear Street Series", label: "Fear Street Series" },
  { value: "Fievel", label: "Fievel" },
  { value: "Fifty Shades Trilogy", label: "Fifty Shades Trilogy" },
  {
    value: "Film Trilogy by Ingmar Bergman",
    label: "Film Trilogy by Ingmar Bergman",
  },
  { value: "Final Destination", label: "Final Destination" },
  { value: "Final Fantasy", label: "Final Fantasy" },
  { value: "Finding Nemo", label: "Finding Nemo" },
  {
    value: "Firefly Series (Rob Zombie)",
    label: "Firefly Series (Rob Zombie)",
  },
  { value: "Flatliners", label: "Flatliners" },
  { value: "Fletch", label: "Fletch" },
  { value: "Footloose", label: "Footloose" },
  { value: "Friday Series", label: "Friday Series" },
  { value: "Friday the 13th", label: "Friday the 13th" },
  { value: "Frozen Franchise", label: "Frozen Franchise" },
  { value: "G.I. Joe Universe", label: "G.I. Joe Universe" },
  { value: "Garfield Universe", label: "Garfield Universe" },
  { value: "Ghost Rider", label: "Ghost Rider" },
  { value: "Ghost in the Shell", label: "Ghost in the Shell" },
  { value: "Ghostbusters", label: "Ghostbusters" },
  { value: "Gnomes", label: "Gnomes" },
  { value: "Godfather", label: "Godfather" },
  { value: "Godzilla Franchise", label: "Godzilla Franchise" },
  {
    value: "Godzilla: The Showa-Era Films",
    label: "Godzilla: The Showa-Era Films",
  },
  { value: "Goosebumps Series", label: "Goosebumps Series" },
  { value: "Gremlins", label: "Gremlins" },
  { value: "Grudge Film Series", label: "Grudge Film Series" },
  { value: "Guardians of the Galaxy", label: "Guardians of the Galaxy" },
  { value: "HBO Max", label: "HBO Max" },
  { value: "Halloween", label: "Halloween" },
  { value: "Hangover Trilogy", label: "Hangover Trilogy" },
  { value: "Hanna-Barbera", label: "Hanna-Barbera" },
  { value: "Hannibal Lecter", label: "Hannibal Lecter" },
  { value: "Happy Death Day", label: "Happy Death Day" },
  { value: "Harold & Kumar", label: "Harold & Kumar" },
  { value: "Has Fallen Franchise", label: "Has Fallen Franchise" },
  { value: "Hellboy", label: "Hellboy" },
  { value: "Hellraiser Franchise", label: "Hellraiser Franchise" },
  {
    value: "Hercule Poirot Series (Agatha Christie)",
    label: "Hercule Poirot Series (Agatha Christie)",
  },
  { value: "Highlander", label: "Highlander" },
  { value: "Hills Have Eyes", label: "Hills Have Eyes" },
  { value: "Hitman", label: "Hitman" },
  { value: "Hitman's Bodyguard Series", label: "Hitman's Bodyguard Series" },
  { value: "Hocus Pocus Series", label: "Hocus Pocus Series" },
  { value: "Home Alone Franchise", label: "Home Alone Franchise" },
  { value: "Honey I Shrunk", label: "Honey I Shrunk" },
  { value: "Horrible Bosses Series", label: "Horrible Bosses Series" },
  { value: "Hostel Franchise", label: "Hostel Franchise" },
  { value: "Hot Tub Time Machine", label: "Hot Tub Time Machine" },
  { value: "Hotel Transylvania", label: "Hotel Transylvania" },
  { value: "How To Train Your Dragon", label: "How To Train Your Dragon" },
  { value: "Hulk", label: "Hulk" },
  { value: "Human Centipede", label: "Human Centipede" },
  { value: "Human Condition Series", label: "Human Condition Series" },
  { value: "Hunger Games", label: "Hunger Games" },
  { value: "Hustler Franchise", label: "Hustler Franchise" },
  { value: "I Am Curious... Box Set", label: "I Am Curious... Box Set" },
  { value: "Ice Age", label: "Ice Age" },
  { value: "Illumination Universe", label: "Illumination Universe" },
  { value: "Incredibles", label: "Incredibles" },
  { value: "Independence Day", label: "Independence Day" },
  { value: "Indiana Jones", label: "Indiana Jones" },
  { value: "Insidious", label: "Insidious" },
  { value: "Iron Man", label: "Iron Man" },
  { value: "It (Pennywise) Universe", label: "It (Pennywise) Universe" },
  { value: "Jack Reacher Series", label: "Jack Reacher Series" },
  { value: "Jack Ryan (Ryanverse)", label: "Jack Ryan (Ryanverse)" },
  { value: "Jackass", label: "Jackass" },
  { value: "Jacob's Ladder Series", label: "Jacob's Ladder Series" },
  { value: "James Bond - Brosnan", label: "James Bond - Brosnan" },
  { value: "James Bond - Connery", label: "James Bond - Connery" },
  { value: "James Bond - Craig", label: "James Bond - Craig" },
  { value: "James Bond - Dalton", label: "James Bond - Dalton" },
  { value: "James Bond - Lazenby", label: "James Bond - Lazenby" },
  { value: "James Bond - Moore", label: "James Bond - Moore" },
  { value: "James Bond - Niven", label: "James Bond - Niven" },
  { value: "Jaws", label: "Jaws" },
  { value: "Jeepers Creepers Franchise", label: "Jeepers Creepers Franchise" },
  {
    value: "John Cassavetes: Five Films",
    label: "John Cassavetes: Five Films",
  },
  { value: "John Grisham-verse", label: "John Grisham-verse" },
  { value: "John Hughes (Shermerverse)", label: "John Hughes (Shermerverse)" },
  { value: "John Wick", label: "John Wick" },
  { value: "Johnny English", label: "Johnny English" },
  { value: "Journey To Franchise", label: "Journey To Franchise" },
  { value: "Judge Dredd", label: "Judge Dredd" },
  { value: "Jumanji", label: "Jumanji" },
  { value: "Jump Street", label: "Jump Street" },
  {
    value: "Jurassic Park Original Trilogy",
    label: "Jurassic Park Original Trilogy",
  },
  {
    value: "Jurassic Park Sequel Trilogy",
    label: "Jurassic Park Sequel Trilogy",
  },
  { value: "Karate Kid", label: "Karate Kid" },
  { value: "Kick-Ass", label: "Kick-Ass" },
  { value: "Killers (3 adaptations)", label: "Killers (3 adaptations)" },
  { value: "Kingsman Franchise", label: "Kingsman Franchise" },
  { value: "Kissing Booth Franchise", label: "Kissing Booth Franchise" },
  { value: "Knives Out Series", label: "Knives Out Series" },
  { value: "Kung Fu Panda", label: "Kung Fu Panda" },
  { value: "LEGO", label: "LEGO" },
  { value: "Lady Snowblood Series", label: "Lady Snowblood Series" },
  { value: "Last Summer Series", label: "Last Summer Series" },
  { value: "Legally Blonde", label: "Legally Blonde" },
  { value: "Leprechaun", label: "Leprechaun" },
  { value: "Lethal Weapon", label: "Lethal Weapon" },
  {
    value: "Letters from Fontainhas: Three Films by Pedro Costa",
    label: "Letters from Fontainhas: Three Films by Pedro Costa",
  },
  { value: "Lion King Franchise", label: "Lion King Franchise" },
  { value: "Living Dead Series", label: "Living Dead Series" },
  {
    value: "Lone Wolf and Cub Collection",
    label: "Lone Wolf and Cub Collection",
  },
  { value: "Lonely Island", label: "Lonely Island" },
  { value: "Look Who's Talking Trilogy", label: "Look Who's Talking Trilogy" },
  { value: "Looney Tunes", label: "Looney Tunes" },
  { value: "Mad Max", label: "Mad Max" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Major League", label: "Major League" },
  { value: "Mamma Mia!", label: "Mamma Mia!" },
  { value: "Marseille Trilogy", label: "Marseille Trilogy" },
  { value: "Mary Poppins", label: "Mary Poppins" },
  { value: "Matrix Film Series", label: "Matrix Film Series" },
  { value: "Maze Runner Series", label: "Maze Runner Series" },
  { value: "Meet the Parents", label: "Meet the Parents" },
  { value: "Men in Black", label: "Men in Black" },
  { value: "Mighty Ducks", label: "Mighty Ducks" },
  { value: "Minions", label: "Minions" },
  { value: "MonsterVerse", label: "MonsterVerse" },
  { value: "Monsters Inc.", label: "Monsters Inc." },
  { value: "Monty Python", label: "Monty Python" },
  { value: "Mortal Kombat Franchise", label: "Mortal Kombat Franchise" },
  { value: "Mr. Bean", label: "Mr. Bean" },
  { value: "Mummy", label: "Mummy" },
  { value: "My Little Pony", label: "My Little Pony" },
  { value: "NFL Universe", label: "NFL Universe" },
  { value: "Naked Gun", label: "Naked Gun" },
  { value: "Narnia", label: "Narnia" },
  { value: "National Lampoon", label: "National Lampoon" },
  { value: "Netflix Christmas", label: "Netflix Christmas" },
  { value: "Nicholas Sparks", label: "Nicholas Sparks" },
  { value: "Nickelodeon", label: "Nickelodeon" },
  { value: "Night at the Museum Series", label: "Night at the Museum Series" },
  { value: "Ninja Turtles", label: "Ninja Turtles" },
  { value: "Nintendo Universe", label: "Nintendo Universe" },
  { value: "Nutty Professor", label: "Nutty Professor" },
  { value: "Omen Franchise", label: "Omen Franchise" },
  { value: "Orphan Series", label: "Orphan Series" },
  { value: "Orphic Trilogy", label: "Orphic Trilogy" },
  { value: "Oslo Trilogy", label: "Oslo Trilogy" },
  { value: "Ouija Series", label: "Ouija Series" },
  { value: "Outrage Trilogy", label: "Outrage Trilogy" },
  { value: "Paramount+ Original", label: "Paramount+ Original" },
  { value: "Paranormal Activity", label: "Paranormal Activity" },
  { value: "Pathé Animation", label: "Pathé Animation" },
  { value: "Peacock Original", label: "Peacock Original" },
  { value: "Peanuts Universe", label: "Peanuts Universe" },
  { value: "Percy Jackson Series", label: "Percy Jackson Series" },
  { value: "Peter Rabbit Series", label: "Peter Rabbit Series" },
  { value: "Pink Panther", label: "Pink Panther" },
  { value: "Pitch Perfect", label: "Pitch Perfect" },
  { value: "Planet of the Apes (New)", label: "Planet of the Apes (New)" },
  { value: "Planet of the Apes (Old)", label: "Planet of the Apes (Old)" },
  { value: "PlayStation", label: "PlayStation" },
  { value: "Pokemon Franchise", label: "Pokemon Franchise" },
  { value: "Police Story Series", label: "Police Story Series" },
  { value: "Predator", label: "Predator" },
  { value: "Princess Switch Trilogy", label: "Princess Switch Trilogy" },
  { value: "Public Domain", label: "Public Domain" },
  { value: "Punisher", label: "Punisher" },
  { value: "Purge Franchise", label: "Purge Franchise" },
  { value: "Pusher Trilogy", label: "Pusher Trilogy" },
  { value: "Raid Franchise", label: "Raid Franchise" },
  { value: "Rambo", label: "Rambo" },
  { value: "Resident Evil", label: "Resident Evil" },
  { value: "Revenge of the Nerds", label: "Revenge of the Nerds" },
  { value: "Ring Series", label: "Ring Series" },
  { value: "Rio Series", label: "Rio Series" },
  { value: "Roku Original", label: "Roku Original" },
  { value: "Rugrats", label: "Rugrats" },
  { value: "Rush Hour", label: "Rush Hour" },
  { value: "Saturday Night Live", label: "Saturday Night Live" },
  { value: "Saw", label: "Saw" },
  { value: "Scary Movie", label: "Scary Movie" },
  { value: "Scream Franchise", label: "Scream Franchise" },
  { value: "Secret Life of Pets", label: "Secret Life of Pets" },
  { value: "Sex and the City", label: "Sex and the City" },
  { value: "Shaft Series", label: "Shaft Series" },
  { value: "Shakespeare Universe", label: "Shakespeare Universe" },
  { value: "Shanghai Series", label: "Shanghai Series" },
  { value: "She's All That Series", label: "She's All That Series" },
  { value: "Shining Series", label: "Shining Series" },
  { value: "Sin City", label: "Sin City" },
  { value: "Sing Series", label: "Sing Series" },
  { value: "Sinister", label: "Sinister" },
  { value: "Sister Act Trilogy", label: "Sister Act Trilogy" },
  {
    value: "Six Moral Tales (Éric Rohmer)",
    label: "Six Moral Tales (Éric Rohmer)",
  },
  { value: "Smurfs", label: "Smurfs" },
  { value: "Snow White", label: "Snow White" },
  { value: "Sonic the Hedgehog", label: "Sonic the Hedgehog" },
  { value: "Sopranos Universe", label: "Sopranos Universe" },
  { value: "Souvenir Series", label: "Souvenir Series" },
  { value: "Space Jam Series", label: "Space Jam Series" },
  { value: "Speed Franchise", label: "Speed Franchise" },
  { value: "Spider-Man", label: "Spider-Man" },
  { value: "Spirit the Stallion", label: "Spirit the Stallion" },
  { value: "SpongeBob", label: "SpongeBob" },
  { value: "Spy Kids", label: "Spy Kids" },
  { value: "Star Trek Kelvin Timline", label: "Star Trek Kelvin Timline" },
  { value: "Star Trek Next Generation", label: "Star Trek Next Generation" },
  { value: "Star Trek Original Series", label: "Star Trek Original Series" },
  { value: "Taken Franchise", label: "Taken Franchise" },
  {
    value: "Tales of the Four Seasons (Éric Rohmer)",
    label: "Tales of the Four Seasons (Éric Rohmer)",
  },
  { value: "Tall Girl Universe", label: "Tall Girl Universe" },
  { value: "Teen Wolf", label: "Teen Wolf" },
  { value: "Terrifier Series", label: "Terrifier Series" },
  { value: "Texas Chain Saw", label: "Texas Chain Saw" },
  { value: "Thor", label: "Thor" },
  { value: "Three Colours Trilogy", label: "Three Colours Trilogy" },
  { value: "Three Flavours Cornetto", label: "Three Flavours Cornetto" },
  { value: "Three War Films Trilogy", label: "Three War Films Trilogy" },
  { value: "To All the Boys Trilogy", label: "To All the Boys Trilogy" },
  { value: "Tomb Raider", label: "Tomb Raider" },
  { value: "Top Gun Franchise", label: "Top Gun Franchise" },
  { value: "Toy Story Franchise", label: "Toy Story Franchise" },
  { value: "Trainspotting", label: "Trainspotting" },
  { value: "Transformers Michael Bay", label: "Transformers Michael Bay" },
  { value: "Transporter", label: "Transporter" },
  { value: "Tremors Franchise", label: "Tremors Franchise" },
  { value: "Trip Franchise", label: "Trip Franchise" },
  { value: "Trolls", label: "Trolls" },
  { value: "Tron", label: "Tron" },
  { value: "Twilight", label: "Twilight" },
  { value: "Unbreakable Trilogy", label: "Unbreakable Trilogy" },
  { value: "Underworld", label: "Underworld" },
  { value: "Unfriended", label: "Unfriended" },
  {
    value: "Universal Soldier Franchise",
    label: "Universal Soldier Franchise",
  },
  { value: "V/H/S Series", label: "V/H/S Series" },
  { value: "Vacation Series", label: "Vacation Series" },
  { value: "Valiant Comics", label: "Valiant Comics" },
  { value: "View Askewniverse", label: "View Askewniverse" },
  { value: "Wall Street Series", label: "Wall Street Series" },
  { value: "Weekend at Bernie's", label: "Weekend at Bernie's" },
  { value: "West Side Story", label: "West Side Story" },
  { value: "Whole Yards Series", label: "Whole Yards Series" },
  { value: "Wizard of Oz Universe", label: "Wizard of Oz Universe" },
  { value: "Wizarding World", label: "Wizarding World" },
  { value: "Wolf Warrior Series", label: "Wolf Warrior Series" },
  { value: "Wreck-It Ralph", label: "Wreck-It Ralph" },
  { value: "Wrong Turn Franchise", label: "Wrong Turn Franchise" },
  { value: "X Horror Series", label: "X Horror Series" },
  { value: "Zombieland", label: "Zombieland" },
  { value: "Zoolander", label: "Zoolander" },
  { value: "Zorro", label: "Zorro" },
  { value: "xXx", label: "xXx" },
];

const universeMarvel: DropdownOption[] = [
  { value: "Marvel (MCU)", label: "Marvel (MCU)" },
  { value: "Marvel (Non-MCU)", label: "Marvel (Non-MCU)" },
  { value: "Marvel (Sony Pictures)", label: "Marvel (Sony Pictures)" },
  { value: "Marvel (X-Men)", label: "Marvel (X-Men)" },
];

const universeDC: DropdownOption[] = [
  { value: "DC (Animated - DCAMU)", label: "DC (Animated - DCAMU)" },
  { value: "DC (DCEU)", label: "DC (DCEU)" },
  { value: "DC (Non-DCEU)", label: "DC (Non-DCEU)" },
  { value: "Dark Knight Trilogy", label: "Dark Knight Trilogy" },
  {
    value: "Superman (Original Universe)",
    label: "Superman (Original Universe)",
  },
  { value: "Tim Burton Batman Series", label: "Tim Burton Batman Series" },
];

const universeStarWars: DropdownOption[] = [
  { value: "Star Wars Universe", label: "Star Wars Universe" },
  { value: "Star Wars Original Trilogy", label: "Star Wars Original Trilogy" },
  { value: "Star Wars Prequel Trilogy", label: "Star Wars Prequel Trilogy" },
  { value: "Star Wars Sequel Trilogy", label: "Star Wars Sequel Trilogy" },
  { value: "Star Wars Standalone", label: "Star Wars Standalone" },
];

const universeDisney: DropdownOption[] = [
  { value: "Disney Animated", label: "Disney Animated" },
  { value: "Disney Animation Studios", label: "Disney Animation Studios" },
  { value: "Pixar", label: "Disney Pixar" },
  { value: "Disney Live-Action", label: "Disney Live-Action" },
  { value: "Disney Live-Action Remake", label: "Disney Live-Action Remake" },
  {
    value: "Disney Channel Original Movie",
    label: "Disney Channel Original Movie",
  },
  { value: "Disney+", label: "Disney+" },
  { value: "Muppets", label: "Muppets" },
  { value: "Pirates of the Caribbean", label: "Pirates of the Caribbean" },
];

const universeLOTR: DropdownOption[] = [
  { value: "Middle-earth", label: "Middle-earth (Lord of the Rings)" },
  { value: "Lord of the Rings Trilogy", label: "Lord of the Rings Trilogy" },
  { value: "Hobbit Trilogy", label: "Hobbit Trilogy" },
];

const universeWizardWorld: DropdownOption[] = [
  {
    value: "Wizarding World",
    label: "Wizarding World (Harry Potter)",
  },
  { value: "Harry Potter Franchise", label: "Harry Potter Franchise" },
  { value: "Fantastic Beasts Franchise", label: "Fantastic Beasts Franchise" },
];

const universeOther: DropdownOption[] = [
  { value: "A Cinderella Story Series", label: "A Cinderella Story Series" },
  { value: "A Nightmare on Elm Street", label: "A Nightmare on Elm Street" },
  { value: "AMC+ Original", label: "AMC+ Original" },
  { value: "Aaron Sorkin Universe", label: "Aaron Sorkin Universe" },
  { value: "Ace Ventura", label: "Ace Ventura" },
  { value: "Addams Family", label: "Addams Family" },
  {
    value: "Adventures of Antoine Doinel",
    label: "Adventures of Antoine Doinel",
  },
  { value: "After Series", label: "After Series" },
  { value: "Airplane!", label: "Airplane!" },
  { value: "Alien vs. Predator", label: "Alien vs. Predator" },
  { value: "Almighty Franchise", label: "Almighty Franchise" },
  { value: "Amazon Prime Original", label: "Amazon Prime Original" },
  { value: "American Pie", label: "American Pie" },
  { value: "Amityville", label: "Amityville" },
  { value: "Analyze This Series", label: "Analyze This Series" },
  { value: "Anchorman", label: "Anchorman" },
  { value: "Animated Christmas", label: "Animated Christmas" },
  { value: "Ant-Man", label: "Ant-Man" },
  { value: "Apocalypse Trilogy", label: "Apocalypse Trilogy" },
  { value: "Apple TV+ Original", label: "Apple TV+ Original" },
  { value: "Apu Trilogy", label: "Apu Trilogy" },
  { value: "Aquaman", label: "Aquaman" },
  { value: "Army of the Dead Universe", label: "Army of the Dead Universe" },
  { value: "Arthur", label: "Arthur" },
  { value: "Austin Powers", label: "Austin Powers" },
  { value: "Avatar Franchise", label: "Avatar Franchise" },
  { value: "Avengers", label: "Avengers" },
  { value: "BRD Trilogy", label: "BRD Trilogy" },
  { value: "Babe", label: "Babe" },
  { value: "Baby Geniuses", label: "Baby Geniuses" },
  { value: "Back to the Future", label: "Back to the Future" },
  { value: "Bad Boys", label: "Bad Boys" },
  { value: "Bad News Bears", label: "Bad News Bears" },
  { value: "Bad Santa Series", label: "Bad Santa Series" },
  { value: "Barbershop", label: "Barbershop" },
  { value: "Before Trilogy", label: "Before Trilogy" },
  { value: "Benson & Moorhead Universe", label: "Benson & Moorhead Universe" },
  { value: "Beverly Hills Cop", label: "Beverly Hills Cop" },
  { value: "Big Momma's House", label: "Big Momma's House" },
  { value: "Bill & Ted", label: "Bill & Ted" },
  { value: "Black Christmas Series", label: "Black Christmas Series" },
  { value: "Black Panther", label: "Black Panther" },
  { value: "Blade Runner", label: "Blade Runner" },
  { value: "Blair Witch", label: "Blair Witch" },
  { value: "Blue Sky Universe", label: "Blue Sky Universe" },
  { value: "Blues Brothers Franchise", label: "Blues Brothers Franchise" },
  { value: "Blumhouse", label: "Blumhouse" },
  { value: "Boondock Saints", label: "Boondock Saints" },
  { value: "Borat Franchise", label: "Borat Franchise" },
  { value: "Boss Baby Universe", label: "Boss Baby Universe" },
  { value: "Brady Bunch", label: "Brady Bunch" },
  { value: "Brahms: The Boy", label: "Brahms: The Boy" },
  { value: "Bridget Jones", label: "Bridget Jones" },
  { value: "Bring It On", label: "Bring It On" },
  { value: "Broken Lizard", label: "Broken Lizard" },
  {
    value: "Bruce Willis On-Demand Universe",
    label: "Bruce Willis On-Demand Universe",
  },
  { value: "Candyman Franchise", label: "Candyman Franchise" },
  { value: "Captain America", label: "Captain America" },
  { value: "Carrie Franchise", label: "Carrie Franchise" },
  { value: "Cars Trilogy", label: "Cars Trilogy" },
  { value: "Cats & Dogs Series", label: "Cats & Dogs Series" },
  { value: "Charlie Kaufman", label: "Charlie Kaufman" },
  { value: "Charlie Kaufman Series", label: "Charlie Kaufman Series" },
  { value: "Charlie's Angels", label: "Charlie's Angels" },
  {
    value: "Cheaper by the Dozen Series",
    label: "Cheaper by the Dozen Series",
  },
  { value: "Child's Play", label: "Child's Play" },
  {
    value: "Christmas Chronicles Series",
    label: "Christmas Chronicles Series",
  },
  { value: "Chronicles of Riddick", label: "Chronicles of Riddick" },
  { value: "Cloverfield", label: "Cloverfield" },
  { value: "Cloverfield Franchise", label: "Cloverfield Franchise" },
  { value: "Collector Franchise", label: "Collector Franchise" },
  {
    value: "Comedies and Proverbs (Éric Rohmer)",
    label: "Comedies and Proverbs (Éric Rohmer)",
  },
  { value: "Coming To America", label: "Coming To America" },
  { value: "Complete Jacques Tati", label: "Complete Jacques Tati" },
  { value: "Complete Jean Vigo", label: "Complete Jean Vigo" },
  { value: "Conjuring Universe", label: "Conjuring Universe" },
  { value: "Crank Franchise", label: "Crank Franchise" },
  { value: "Criterion Collection", label: "Criterion Collection" },
  { value: "Critters Franchise", label: "Critters Franchise" },
  { value: "Crocodile Dundee Films", label: "Crocodile Dundee Films" },
  { value: "Croods Universe", label: "Croods Universe" },
  { value: "Da Vinci Code Trilogy", label: "Da Vinci Code Trilogy" },
  { value: "Daddy Universe", label: "Daddy Universe" },
  { value: "Dark Universe", label: "Dark Universe" },
  {
    value: "David Lean Directs Noël Coward",
    label: "David Lean Directs Noël Coward",
  },
  { value: "Death Race", label: "Death Race" },
  { value: "Diary of a Wimpy Kid", label: "Diary of a Wimpy Kid" },
  { value: "Die Hard", label: "Die Hard" },
  {
    value: "Dietrich & von Sternberg in Hollywood",
    label: "Dietrich & von Sternberg in Hollywood",
  },
  { value: "Dirty Harry", label: "Dirty Harry" },
  { value: "Disney Theme Park Rides", label: "Disney Theme Park Rides" },
  { value: "Divergent Series", label: "Divergent Series" },
  { value: "Dollars Trilogy", label: "Dollars Trilogy" },
  { value: "Dolphin Tale Series", label: "Dolphin Tale Series" },
  { value: "Don't Breathe Series", label: "Don't Breathe Series" },
  { value: "Downton Abbey Films", label: "Downton Abbey Films" },
  { value: "Dr. Dolittle", label: "Dr. Dolittle" },
  { value: "Dr. Seuss", label: "Dr. Seuss" },
  { value: "Dragon Ball Z", label: "Dragon Ball Z" },
  { value: "Dragon Tattoo Stories", label: "Dragon Tattoo Stories" },
  { value: "Dumb and Dumber", label: "Dumb and Dumber" },
  { value: "Elite Squad", label: "Elite Squad" },
  { value: "Equalizer Franchise", label: "Equalizer Franchise" },
  { value: "Ernest Franchise", label: "Ernest Franchise" },
  { value: "Escape From Series", label: "Escape From Series" },
  { value: "Escape Plan Series", label: "Escape Plan Series" },
  { value: "Escape Room Series", label: "Escape Room Series" },
  { value: "Essential Jacques Demy", label: "Essential Jacques Demy" },
  { value: "Europa Trilogy", label: "Europa Trilogy" },
  { value: "Evil Dead", label: "Evil Dead" },
  { value: "Expendables Franchise", label: "Expendables Franchise" },
  { value: "Fantastic Four", label: "Fantastic Four" },
  { value: "Father & Son Series", label: "Father & Son Series" },
  { value: "Fear Street Series", label: "Fear Street Series" },
  { value: "Fievel", label: "Fievel" },
  { value: "Fifty Shades Trilogy", label: "Fifty Shades Trilogy" },
  {
    value: "Film Trilogy by Ingmar Bergman",
    label: "Film Trilogy by Ingmar Bergman",
  },
  { value: "Final Destination", label: "Final Destination" },
  { value: "Final Fantasy", label: "Final Fantasy" },
  { value: "Finding Nemo", label: "Finding Nemo" },
  {
    value: "Firefly Series (Rob Zombie)",
    label: "Firefly Series (Rob Zombie)",
  },
  { value: "Flatliners", label: "Flatliners" },
  { value: "Fletch", label: "Fletch" },
  { value: "Footloose", label: "Footloose" },
  { value: "Friday Series", label: "Friday Series" },
  { value: "Friday the 13th", label: "Friday the 13th" },
  { value: "Frozen Franchise", label: "Frozen Franchise" },
  { value: "G.I. Joe Universe", label: "G.I. Joe Universe" },
  { value: "Garfield Universe", label: "Garfield Universe" },
  { value: "Ghost Rider", label: "Ghost Rider" },
  { value: "Ghost in the Shell", label: "Ghost in the Shell" },
  { value: "Ghostbusters", label: "Ghostbusters" },
  { value: "Gnomes", label: "Gnomes" },
  { value: "Godfather", label: "Godfather" },
  { value: "Godzilla Franchise", label: "Godzilla Franchise" },
  {
    value: "Godzilla: The Showa-Era Films",
    label: "Godzilla: The Showa-Era Films",
  },
  { value: "Goosebumps Series", label: "Goosebumps Series" },
  { value: "Gremlins", label: "Gremlins" },
  { value: "Grudge Film Series", label: "Grudge Film Series" },
  { value: "Guardians of the Galaxy", label: "Guardians of the Galaxy" },
  { value: "HBO Max", label: "HBO Max" },
  { value: "Halloween", label: "Halloween" },
  { value: "Hangover Trilogy", label: "Hangover Trilogy" },
  { value: "Hanna-Barbera", label: "Hanna-Barbera" },
  { value: "Hannibal Lecter", label: "Hannibal Lecter" },
  { value: "Happy Death Day", label: "Happy Death Day" },
  { value: "Harold & Kumar", label: "Harold & Kumar" },
  { value: "Has Fallen Franchise", label: "Has Fallen Franchise" },
  { value: "Hellboy", label: "Hellboy" },
  { value: "Hellraiser Franchise", label: "Hellraiser Franchise" },
  {
    value: "Hercule Poirot Series (Agatha Christie)",
    label: "Hercule Poirot Series (Agatha Christie)",
  },
  { value: "Highlander", label: "Highlander" },
  { value: "Hills Have Eyes", label: "Hills Have Eyes" },
  { value: "Hitman", label: "Hitman" },
  { value: "Hitman's Bodyguard Series", label: "Hitman's Bodyguard Series" },
  { value: "Hocus Pocus Series", label: "Hocus Pocus Series" },
  { value: "Home Alone Franchise", label: "Home Alone Franchise" },
  { value: "Honey I Shrunk", label: "Honey I Shrunk" },
  { value: "Horrible Bosses Series", label: "Horrible Bosses Series" },
  { value: "Hostel Franchise", label: "Hostel Franchise" },
  { value: "Hot Tub Time Machine", label: "Hot Tub Time Machine" },
  { value: "Hotel Transylvania", label: "Hotel Transylvania" },
  { value: "How To Train Your Dragon", label: "How To Train Your Dragon" },
  { value: "Hulk", label: "Hulk" },
  { value: "Human Centipede", label: "Human Centipede" },
  { value: "Human Condition Series", label: "Human Condition Series" },
  { value: "Hunger Games", label: "Hunger Games" },
  { value: "Hustler Franchise", label: "Hustler Franchise" },
  { value: "I Am Curious... Box Set", label: "I Am Curious... Box Set" },
  { value: "Ice Age", label: "Ice Age" },
  { value: "Illumination Universe", label: "Illumination Universe" },
  { value: "Incredibles", label: "Incredibles" },
  { value: "Independence Day", label: "Independence Day" },
  { value: "Indiana Jones", label: "Indiana Jones" },
  { value: "Insidious", label: "Insidious" },
  { value: "Iron Man", label: "Iron Man" },
  { value: "It (Pennywise) Universe", label: "It (Pennywise) Universe" },
  { value: "Jack Reacher Series", label: "Jack Reacher Series" },
  { value: "Jack Ryan (Ryanverse)", label: "Jack Ryan (Ryanverse)" },
  { value: "Jackass", label: "Jackass" },
  { value: "Jacob's Ladder Series", label: "Jacob's Ladder Series" },
  { value: "James Bond - Brosnan", label: "James Bond - Brosnan" },
  { value: "James Bond - Connery", label: "James Bond - Connery" },
  { value: "James Bond - Craig", label: "James Bond - Craig" },
  { value: "James Bond - Dalton", label: "James Bond - Dalton" },
  { value: "James Bond - Lazenby", label: "James Bond - Lazenby" },
  { value: "James Bond - Moore", label: "James Bond - Moore" },
  { value: "James Bond - Niven", label: "James Bond - Niven" },
  { value: "Jaws", label: "Jaws" },
  { value: "Jeepers Creepers", label: "Jeepers Creepers" },
  { value: "Jeepers Creepers Franchise", label: "Jeepers Creepers Franchise" },
  {
    value: "John Cassavetes: Five Films",
    label: "John Cassavetes: Five Films",
  },
  { value: "John Grisham-verse", label: "John Grisham-verse" },
  { value: "John Hughes (Shermerverse)", label: "John Hughes (Shermerverse)" },
  { value: "John Wick", label: "John Wick" },
  { value: "Johnny English", label: "Johnny English" },
  { value: "Journey To Franchise", label: "Journey To Franchise" },
  { value: "Judge Dredd", label: "Judge Dredd" },
  { value: "Jumanji", label: "Jumanji" },
  { value: "Jump Street", label: "Jump Street" },
  {
    value: "Jurassic Park Original Trilogy",
    label: "Jurassic Park Original Trilogy",
  },
  {
    value: "Jurassic Park Sequel Trilogy",
    label: "Jurassic Park Sequel Trilogy",
  },
  { value: "Karate Kid", label: "Karate Kid" },
  { value: "Kick-Ass", label: "Kick-Ass" },
  { value: "Killers (3 adaptations)", label: "Killers (3 adaptations)" },
  { value: "Kingsman Franchise", label: "Kingsman Franchise" },
  { value: "Kissing Booth Franchise", label: "Kissing Booth Franchise" },
  { value: "Knives Out Series", label: "Knives Out Series" },
  { value: "Kung Fu Panda", label: "Kung Fu Panda" },
  { value: "LEGO", label: "LEGO" },
  { value: "Lady Snowblood Series", label: "Lady Snowblood Series" },
  { value: "Last Summer Series", label: "Last Summer Series" },
  { value: "Legally Blonde", label: "Legally Blonde" },
  { value: "Leprechaun", label: "Leprechaun" },
  { value: "Lethal Weapon", label: "Lethal Weapon" },
  {
    value: "Letters from Fontainhas: Three Films by Pedro Costa",
    label: "Letters from Fontainhas: Three Films by Pedro Costa",
  },
  { value: "Lion King Franchise", label: "Lion King Franchise" },
  { value: "Living Dead Series", label: "Living Dead Series" },
  {
    value: "Lone Wolf and Cub Collection",
    label: "Lone Wolf and Cub Collection",
  },
  { value: "Lonely Island", label: "Lonely Island" },
  { value: "Look Who's Talking Trilogy", label: "Look Who's Talking Trilogy" },
  { value: "Looney Tunes", label: "Looney Tunes" },
  { value: "Mad Max", label: "Mad Max" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Major League", label: "Major League" },
  { value: "Mamma Mia!", label: "Mamma Mia!" },
  { value: "Marseille Trilogy", label: "Marseille Trilogy" },
  { value: "Mary Poppins", label: "Mary Poppins" },
  { value: "Matrix Film Series", label: "Matrix Film Series" },
  { value: "Maze Runner Series", label: "Maze Runner Series" },
  { value: "Meet the Parents", label: "Meet the Parents" },
  { value: "Men in Black", label: "Men in Black" },
  { value: "Mighty Ducks", label: "Mighty Ducks" },
  { value: "Minions", label: "Minions" },
  { value: "MonsterVerse", label: "MonsterVerse" },
  { value: "Monsters Inc.", label: "Monsters Inc." },
  { value: "Monty Python", label: "Monty Python" },
  { value: "Mortal Kombat Franchise", label: "Mortal Kombat Franchise" },
  { value: "Mr. Bean", label: "Mr. Bean" },
  { value: "Mummy", label: "Mummy" },
  { value: "My Little Pony", label: "My Little Pony" },
  { value: "NFL Universe", label: "NFL Universe" },
  { value: "Naked Gun", label: "Naked Gun" },
  { value: "Narnia", label: "Narnia" },
  { value: "National Lampoon", label: "National Lampoon" },
  { value: "Netflix Christmas", label: "Netflix Christmas" },
  { value: "Nicholas Sparks", label: "Nicholas Sparks" },
  { value: "Nickelodeon", label: "Nickelodeon" },
  { value: "Ninja Turtles", label: "Ninja Turtles" },
  { value: "Nintendo Universe", label: "Nintendo Universe" },
  { value: "Nutty Professor", label: "Nutty Professor" },
  { value: "Omen Franchise", label: "Omen Franchise" },
  { value: "Orphan Series", label: "Orphan Series" },
  { value: "Orphic Trilogy", label: "Orphic Trilogy" },
  { value: "Oslo Trilogy", label: "Oslo Trilogy" },
  { value: "Ouija Series", label: "Ouija Series" },
  { value: "Outrage Trilogy", label: "Outrage Trilogy" },
  { value: "Paramount+ Original", label: "Paramount+ Original" },
  { value: "Paranormal Activity", label: "Paranormal Activity" },
  { value: "Pathé Animation", label: "Pathé Animation" },
  { value: "Peacock Original", label: "Peacock Original" },
  { value: "Peanuts Universe", label: "Peanuts Universe" },
  { value: "Percy Jackson Series", label: "Percy Jackson Series" },
  { value: "Peter Rabbit Series", label: "Peter Rabbit Series" },
  { value: "Pink Panther", label: "Pink Panther" },
  { value: "Pitch Perfect", label: "Pitch Perfect" },
  { value: "Planet of the Apes (New)", label: "Planet of the Apes (New)" },
  { value: "Planet of the Apes (Old)", label: "Planet of the Apes (Old)" },
  { value: "PlayStation", label: "PlayStation" },
  { value: "Pokemon Franchise", label: "Pokemon Franchise" },
  { value: "Police Story Series", label: "Police Story Series" },
  { value: "Predator", label: "Predator" },
  { value: "Princess Switch Trilogy", label: "Princess Switch Trilogy" },
  { value: "Public Domain", label: "Public Domain" },
  { value: "Punisher", label: "Punisher" },
  { value: "Purge Franchise", label: "Purge Franchise" },
  { value: "Pusher Trilogy", label: "Pusher Trilogy" },
  { value: "Raid Franchise", label: "Raid Franchise" },
  { value: "Rambo", label: "Rambo" },
  { value: "Resident Evil", label: "Resident Evil" },
  { value: "Revenge of the Nerds", label: "Revenge of the Nerds" },
  { value: "Ring Series", label: "Ring Series" },
  { value: "Rio Series", label: "Rio Series" },
  { value: "Rugrats", label: "Rugrats" },
  { value: "Rush Hour", label: "Rush Hour" },
  { value: "Saturday Night Live", label: "Saturday Night Live" },
  { value: "Saw", label: "Saw" },
  { value: "Scary Movie", label: "Scary Movie" },
  { value: "Scream Franchise", label: "Scream Franchise" },
  { value: "Secret Life of Pets", label: "Secret Life of Pets" },
  { value: "Sex and the City", label: "Sex and the City" },
  { value: "Shakespeare Universe", label: "Shakespeare Universe" },
  { value: "Shanghai Series", label: "Shanghai Series" },
  { value: "She's All That Series", label: "She's All That Series" },
  { value: "Shining Series", label: "Shining Series" },
  { value: "Sin City", label: "Sin City" },
  { value: "Sing Series", label: "Sing Series" },
  { value: "Sinister", label: "Sinister" },
  { value: "Sister Act Trilogy", label: "Sister Act Trilogy" },
  {
    value: "Six Moral Tales (Éric Rohmer)",
    label: "Six Moral Tales (Éric Rohmer)",
  },
  { value: "Smurfs", label: "Smurfs" },
  { value: "Snow White", label: "Snow White" },
  { value: "Sonic the Hedgehog", label: "Sonic the Hedgehog" },
  { value: "Sopranos Universe", label: "Sopranos Universe" },
  { value: "Souvenir Series", label: "Souvenir Series" },
  { value: "Space Jam Series", label: "Space Jam Series" },
  { value: "Speed Franchise", label: "Speed Franchise" },
  { value: "Spider-Man", label: "Spider-Man" },
  { value: "Spirit the Stallion", label: "Spirit the Stallion" },
  { value: "SpongeBob", label: "SpongeBob" },
  { value: "Spy Kids", label: "Spy Kids" },
  { value: "Star Trek Kelvin Timline", label: "Star Trek Kelvin Timline" },
  { value: "Star Trek Next Generation", label: "Star Trek Next Generation" },
  { value: "Star Trek Original Series", label: "Star Trek Original Series" },
  { value: "Taken Franchise", label: "Taken Franchise" },
  {
    value: "Tales of the Four Seasons (Éric Rohmer)",
    label: "Tales of the Four Seasons (Éric Rohmer)",
  },
  { value: "Tall Girl Universe", label: "Tall Girl Universe" },
  { value: "Teen Wolf", label: "Teen Wolf" },
  { value: "Terrifier Series", label: "Terrifier Series" },
  { value: "Texas Chain Saw", label: "Texas Chain Saw" },
  { value: "Thor", label: "Thor" },
  { value: "Three Colours Trilogy", label: "Three Colours Trilogy" },
  { value: "Three Flavours Cornetto", label: "Three Flavours Cornetto" },
  { value: "Three War Films Trilogy", label: "Three War Films Trilogy" },
  { value: "To All the Boys Trilogy", label: "To All the Boys Trilogy" },
  { value: "Tomb Raider", label: "Tomb Raider" },
  { value: "Top Gun Franchise", label: "Top Gun Franchise" },
  { value: "Toy Story Franchise", label: "Toy Story Franchise" },
  { value: "Trainspotting", label: "Trainspotting" },
  { value: "Transformers Michael Bay", label: "Transformers Michael Bay" },
  { value: "Transporter", label: "Transporter" },
  { value: "Tremors Franchise", label: "Tremors Franchise" },
  { value: "Trip Franchise", label: "Trip Franchise" },
  { value: "Trolls", label: "Trolls" },
  { value: "Tron", label: "Tron" },
  { value: "Twilight", label: "Twilight" },
  { value: "Unbreakable Trilogy", label: "Unbreakable Trilogy" },
  { value: "Underworld", label: "Underworld" },
  { value: "Unfriended", label: "Unfriended" },
  {
    value: "Universal Soldier Franchise",
    label: "Universal Soldier Franchise",
  },
  { value: "V/H/S Series", label: "V/H/S Series" },
  { value: "Vacation Series", label: "Vacation Series" },
  { value: "Valiant Comics", label: "Valiant Comics" },
  { value: "View Askewniverse", label: "View Askewniverse" },
  { value: "Wall Street Series", label: "Wall Street Series" },
  { value: "Weekend at Bernie's", label: "Weekend at Bernie's" },
  { value: "West Side Story", label: "West Side Story" },
  { value: "Whole Yards Series", label: "Whole Yards Series" },
  { value: "Wizard of Oz Universe", label: "Wizard of Oz Universe" },
  { value: "Wizarding World", label: "Wizarding World" },
  { value: "Wolf Warrior Series", label: "Wolf Warrior Series" },
  { value: "Wreck-It Ralph", label: "Wreck-It Ralph" },
  { value: "Wrong Turn Franchise", label: "Wrong Turn Franchise" },
  { value: "X Horror Series", label: "X Horror Series" },
  { value: "Zombieland", label: "Zombieland" },
  { value: "Zoolander", label: "Zoolander" },
  { value: "Zorro", label: "Zorro" },
  { value: "xXx", label: "xXx" },
];

export const universeOptions: object[] = [
  {
    label: "Marvel",
    options: universeMarvel,
  },
  {
    label: "DC",
    options: universeDC,
  },
  {
    label: "Star Wars",
    options: universeStarWars,
  },
  {
    label: "Disney",
    options: universeDisney,
  },
  {
    label: "Lord of The Rings",
    options: universeLOTR,
  },
  {
    label: "Wizarding World",
    options: universeWizardWorld,
  },
  {
    label: "Featured",
    options: universeFeatured,
  },
  {
    label: "Other Universes",
    options: universeOther,
  },
];

export const countryOptions: DropdownOption[] = [
  { value: "Albania", label: "Albania" },
  { value: "Argentina", label: "Argentina" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Belgium", label: "Belgium" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Brazil", label: "Brazil" },
  { value: "Canada", label: "Canada" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "England", label: "England" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Greece", label: "Greece" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Mexico", label: "Mexico" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Palestine", label: "Palestine" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Scotland", label: "Scotland" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "South Korea", label: "South Korea" },
  { value: "Spain", label: "Spain" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Thailand", label: "Thailand" },
  { value: "Turkey", label: "Turkey" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yugoslavia", label: "Yugoslavia" },
];

export const characterOptions: DropdownOption[] = [
  { value: "Adam Sandler", label: "Adam Sandler" },
  { value: "Ant-Man", label: "Ant-Man" },
  { value: "Aquaman", label: "Aquaman" },
  { value: "Avengers", label: "Avengers" },
  { value: "Baron Munchausen", label: "Baron Munchausen" },
  { value: "Batman", label: "Batman" },
  { value: "Black Adam", label: "Black Adam" },
  { value: "Black Panther", label: "Black Panther" },
  { value: "Black Widow", label: "Black Widow" },
  { value: "Blade", label: "Blade" },
  { value: "Bruce Lee", label: "Bruce Lee" },
  { value: "Captain America", label: "Captain America" },
  { value: "Captain Marvel", label: "Captain Marvel" },
  { value: "Cat in the Hat", label: "Cat in the Hat" },
  { value: "Catwoman", label: "Catwoman" },
  { value: "Chucky", label: "Chucky" },
  { value: "Cinderella", label: "Cinderella" },
  { value: "Constantine", label: "Constantine" },
  { value: "Creed", label: "Creed" },
  { value: "Cyborg", label: "Cyborg" },
  { value: "Damien Omen", label: "Damien Omen" },
  { value: "Daredevil", label: "Daredevil" },
  { value: "Deadpool", label: "Deadpool" },
  { value: "Doctor Strange", label: "Doctor Strange" },
  { value: "Dracula", label: "Dracula" },
  { value: "Elektra", label: "Elektra" },
  { value: "Eternals", label: "Eternals" },
  { value: "Falcon", label: "Falcon" },
  { value: "Fantastic Four", label: "Fantastic Four" },
  { value: "Flash", label: "Flash" },
  { value: "Flash Gordon", label: "Flash Gordon" },
  { value: "Flintstones", label: "Flintstones" },
  { value: "Frankenstein", label: "Frankenstein" },
  { value: "Freddy Krueger", label: "Freddy Krueger" },
  { value: "Ghost Rider", label: "Ghost Rider" },
  { value: "Godzilla", label: "Godzilla" },
  { value: "Green Lantern", label: "Green Lantern" },
  { value: "Grinch", label: "Grinch" },
  { value: "Guardians of the Galaxy", label: "Guardians of the Galaxy" },
  { value: "Harley Quinn", label: "Harley Quinn" },
  { value: "Hawkeye", label: "Hawkeye" },
  { value: "Hellboy", label: "Hellboy" },
  { value: "Howard the Duck", label: "Howard the Duck" },
  { value: "Hulk", label: "Hulk" },
  { value: "Indiana Jones", label: "Indiana Jones" },
  { value: "Invisible Man", label: "Invisible Man" },
  { value: "Iron Man", label: "Iron Man" },
  { value: "James Bond", label: "James Bond" },
  { value: "Jason Voorhees", label: "Jason Voorhees" },
  { value: "Jaws", label: "Jaws" },
  { value: "Jigsaw", label: "Jigsaw" },
  { value: "Johnny English", label: "Johnny English" },
  { value: "Joker", label: "Joker" },
  { value: "Jonah Hex", label: "Jonah Hex" },
  { value: "Justice League", label: "Justice League" },
  { value: "King Kong", label: "King Kong" },
  { value: "Lara Croft", label: "Lara Croft" },
  { value: "Leatherface", label: "Leatherface" },
  { value: "Leprechaun", label: "Leprechaun" },
  { value: "Loki", label: "Loki" },
  { value: "Looney Tunes", label: "Looney Tunes" },
  { value: "Magneto", label: "Magneto" },
  { value: "Man with No Name", label: "Man with No Name" },
  { value: "Marios Bros.", label: "Marios Bros." },
  { value: "Michael Myers", label: "Michael Myers" },
  { value: "Mr. Bean", label: "Mr. Bean" },
  { value: "Mummy", label: "Mummy" },
  { value: "Nic Cage", label: "Nic Cage" },
  { value: "Nick Fury", label: "Nick Fury" },
  { value: "Nutty Professor", label: "Nutty Professor" },
  { value: "Paul Blart", label: "Paul Blart" },
  { value: "Pee-wee", label: "Pee-wee" },
  { value: "Pennywise", label: "Pennywise" },
  { value: "Pinhead", label: "Pinhead" },
  { value: "Pink Panther", label: "Pink Panther" },
  { value: "Predator", label: "Predator" },
  { value: "Professor X", label: "Professor X" },
  { value: "Punisher", label: "Punisher" },
  { value: "Puss in Boots", label: "Puss in Boots" },
  { value: "Quicksilver", label: "Quicksilver" },
  { value: "Rambo", label: "Rambo" },
  { value: "Riddick", label: "Riddick" },
  { value: "Rocky Balboa", label: "Rocky Balboa" },
  { value: "Ron Burgundy", label: "Ron Burgundy" },
  { value: "Scarlet Witch", label: "Scarlet Witch" },
  { value: "Scooby-Doo", label: "Scooby-Doo" },
  { value: "Shang-Chi", label: "Shang-Chi" },
  { value: "Shazam!", label: "Shazam!" },
  { value: "Sherlock Holmes", label: "Sherlock Holmes" },
  { value: "Shrek", label: "Shrek" },
  { value: "Simpsons", label: "Simpsons" },
  { value: "Snake Plissken", label: "Snake Plissken" },
  { value: "Spider-Man", label: "Spider-Man" },
  { value: "Stu Feiner", label: "Stu Feiner" },
  { value: "Suicide Squad", label: "Suicide Squad" },
  { value: "Supergirl", label: "Supergirl" },
  { value: "Superman", label: "Superman" },
  { value: "Swamp Thing", label: "Swamp Thing" },
  { value: "Teen Titans", label: "Teen Titans" },
  { value: "Thanos", label: "Thanos" },
  { value: "The Wasp", label: "The Wasp" },
  { value: "Thor", label: "Thor" },
  { value: "Van Helsing", label: "Van Helsing" },
  { value: "Venom", label: "Venom" },
  { value: "Vision", label: "Vision" },
  { value: "War Machine", label: "War Machine" },
  { value: "Willy Wonka", label: "Willy Wonka" },
  { value: "Winter Soldier", label: "Winter Soldier" },
  { value: "Wolf Man", label: "Wolf Man" },
  { value: "Wolfman", label: "Wolfman" },
  { value: "Wolverine", label: "Wolverine" },
  { value: "Wonder Woman", label: "Wonder Woman" },
  { value: "X-Men", label: "X-Men" },
  { value: "Xander Cage", label: "Xander Cage" },
];

const sportOptions: DropdownOption[] = [
  { value: "Baseball", label: "Baseball" },
  { value: "Basketball", label: "Basketball" },
  { value: "Billiards", label: "Billiards" },
  { value: "Bowling", label: "Bowling" },
  { value: "Boxing", label: "Boxing" },
  { value: "Cheerleading", label: "Cheerleading" },
  { value: "Chess", label: "Chess" },
  { value: "Dodgeball", label: "Dodgeball" },
  { value: "Football", label: "Football" },
  { value: "Golf", label: "Golf" },
  { value: "Halloween", label: "Halloween" },
  { value: "Hockey", label: "Hockey" },
  { value: "Horse Racing", label: "Horse Racing" },
  { value: "Martial Arts", label: "Martial Arts" },
  { value: "Olympics", label: "Olympics" },
  { value: "Poker", label: "Poker" },
  { value: "Racing", label: "Racing" },
  { value: "Rugby", label: "Rugby" },
  { value: "Running", label: "Running" },
  { value: "Soccer", label: "Soccer" },
  { value: "Surfing", label: "Surfing" },
  { value: "Table Tennis", label: "Table Tennis" },
  { value: "Tennis", label: "Tennis" },
  { value: "Volleyball", label: "Volleyball" },
  { value: "Wrestling", label: "Wrestling" },
  { value: "X-Games", label: "X-Games" },
];

const holidayOptions: DropdownOption[] = [
  { value: "Christmas", label: "Christmas" },
  { value: "Easter", label: "Easter" },
  { value: "Halloween", label: "Halloween" },
  { value: "New Year's Eve", label: "New Year's Eve" },
  { value: "Thanksgiving", label: "Thanksgiving" },
  { value: "Valentine's Day", label: "Valentine's Day" },
];

export const sportholidayOptions: object[] = [
  {
    label: "Holidays",
    options: holidayOptions,
  },
  {
    label: "Sports",
    options: sportOptions,
  },
];

export const yearOptions: DropdownOption[] = [
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
  { value: "1969", label: "1969" },
  { value: "1968", label: "1968" },
  { value: "1967", label: "1967" },
  { value: "1966", label: "1966" },
  { value: "1965", label: "1965" },
  { value: "1964", label: "1964" },
  { value: "1963", label: "1963" },
  { value: "1962", label: "1962" },
  { value: "1961", label: "1961" },
  { value: "1960", label: "1960" },
  { value: "1959", label: "1959" },
  { value: "1958", label: "1958" },
  { value: "1957", label: "1957" },
  { value: "1956", label: "1956" },
  { value: "1955", label: "1955" },
  { value: "1954", label: "1954" },
  { value: "1953", label: "1953" },
  { value: "1952", label: "1952" },
  { value: "1951", label: "1951" },
  { value: "1950", label: "1950" },
  { value: "1949", label: "1949" },
  { value: "1948", label: "1948" },
  { value: "1947", label: "1947" },
  { value: "1946", label: "1946" },
  { value: "1945", label: "1945" },
  { value: "1944", label: "1944" },
  { value: "1943", label: "1943" },
  { value: "1942", label: "1942" },
  { value: "1941", label: "1941" },
  { value: "1940", label: "1940" },
  { value: "1939", label: "1939" },
  { value: "1938", label: "1938" },
  { value: "1937", label: "1937" },
  { value: "1936", label: "1936" },
  { value: "1935", label: "1935" },
  { value: "1933", label: "1933" },
  { value: "1932", label: "1932" },
  { value: "1931", label: "1931" },
  { value: "1928", label: "1928" },
  { value: "1927", label: "1927" },
  { value: "1925", label: "1925" },
  { value: "1922", label: "1922" },
  { value: "1902", label: "1902" },
];

export const decadeOptions: DropdownOption[] = [
  { value: "1900-1909", label: "1900-1909" },
  { value: "1910-1919", label: "1910-1919" },
  { value: "1920-1929", label: "1920-1929" },
  { value: "1930-1939", label: "1930-1939" },
  { value: "1940-1949", label: "1940-1949" },
  { value: "1950-1959", label: "1950-1959" },
  { value: "1960-1969", label: "1960-1969" },
  { value: "1970-1979", label: "1970-1979" },
  { value: "1980-1989", label: "1980-1989" },
  { value: "1990-1999", label: "1990-1999" },
  { value: "2000-2009", label: "2000-2009" },
  { value: "2010-2019", label: "2010-2019" },
  { value: "2020-2029", label: "2020-2029" },
];

export const provider_names: { [key: string]: string } = {
  "9": "Amazon (Stream)",
  "350": "Apple TV",
  "289": "Cinemax",
  "258": "Critereon",
  "337": "Disney+",
  "34": "EPiX",
  "331": "FlixFling",
  "257": "Fubo",
  "123": "FXNow",
  "27@31": "HBO",
  "384": "HBO Max",
  "212": "Hoopla",
  "15": "Hulu",
  "238": "IMDbTV",
  "191": "Kanopy",
  "8": "Netflix",
  "531": "Paramount+",
  "386": "Peacock",
  "387": "Peacock Premium",
  "207": "Roku",
  "37": "Showtime",
  "99": "Shudder",
  "43": "Starz",
  "215": "SyFy",
  "363": "TNT",
  "73": "Tubi",
  "322": "USA",
  "7@332": "Vudu",
  "10": "Amazon (Buy/Rent)",
  "352": "AMC",
  "2": "Apple",
  "358": "DirecTV",
  "105": "Fandango",
  "3": "Google",
  "299": "Sling",
  "192": "YouTube",
};

const topOptions: DropdownOption[] = [
  { value: "9", label: "Amazon (Stream)" },
  { value: "258", label: "Criterion" },
  { value: "337", label: "Disney+" },
  { value: "27@31", label: "HBO" },
  { value: "384", label: "HBO Max" },
  { value: "15", label: "Hulu" },
  { value: "8", label: "Netflix" },
  { value: "531", label: "Paramount+" },
  { value: "386", label: "Peacock" },
  { value: "387", label: "Peacock Premium" },
  { value: "37", label: "Showtime" },
  { value: "43", label: "Starz" },
];

const streamingOptions: DropdownOption[] = [
  { value: "350", label: "Apple TV" },
  { value: "289", label: "Cinemax" },
  { value: "34", label: "EPiX" },
  { value: "331", label: "FlixFling" },
  { value: "257", label: "Fubo" },
  { value: "123", label: "FXNow" },
  { value: "212", label: "Hoopla" },
  { value: "238", label: "IMDbTV" },
  { value: "191", label: "Kanopy" },
  { value: "207", label: "Roku" },
  { value: "99", label: "Shudder" },
  { value: "215", label: "SyFy" },
  { value: "363", label: "TNT" },
  { value: "73", label: "Tubi" },
  { value: "322", label: "USA" },
  { value: "7@332", label: "Vudu" },
];

const buyrentOptions: DropdownOption[] = [
  { value: "10", label: "Amazon (Buy/Rent)" },
  { value: "352", label: "AMC" },
  { value: "2", label: "Apple" },
  { value: "358", label: "DirecTV" },
  { value: "105", label: "Fandango" },
  { value: "3", label: "Google" },
  { value: "299", label: "Sling" },
  { value: "192", label: "YouTube" },
];

export const providerOptions: object[] = [
  {
    label: "",
    options: topOptions.map(({ value, label }) => {
      return {
        value: value,
        label: (
          <span className="filter-flex">
            {label}{" "}
            <img
              className="filter-icon"
              src={require(`../media/providers/${value.split("@")[0]}.png`)}
              alt={value}
            />
          </span>
        ),
      };
    }),
  },
  {
    label: "Streaming",
    options: streamingOptions.map(({ value, label }) => {
      return {
        value: value,
        label: (
          <span className="filter-flex">
            {label}{" "}
            <img
              className="filter-icon"
              src={require(`../media/providers/${value.split("@")[0]}.png`)}
              alt={value}
            />
          </span>
        ),
      };
    }),
  },
  {
    label: "Buy/Rent",
    options: buyrentOptions.map(({ value, label }) => {
      return {
        value: value,
        label: (
          <span className="filter-flex">
            {label}{" "}
            <img
              className="filter-icon"
              src={require(`../media/providers/${value.split("@")[0]}.png`)}
              alt={value}
            />
          </span>
        ),
      };
    }),
  },
];

const oscarOptions: DropdownOption[] = [
  {
    value: "Best Animated Picture (Nominated)@Best Animated Picture (Winner)",
    label: "Best Animated Picture (Nominated)",
  },
  {
    value: "Best Animated Picture (Winner)",
    label: "Best Animated Picture (Winner)",
  },
  {
    value: "Best Picture (Nominated)@Best Picture (Winner)",
    label: "Best Picture (Nominated)",
  },
  { value: "Best Picture (Winner)", label: "Best Picture (Winner)" },
];

const globesOptions: DropdownOption[] = [
  {
    value: "Best Picture: Drama (Nominated)@Best Picture: Drama (Winner)",
    label: "Best Picture: Drama (Nominated)",
  },
  {
    value: "Best Picture: Drama (Winner)",
    label: "Best Picture: Drama (Winner)",
  },
  {
    value:
      "Best Picture: Musical or Comedy (Nominated)@Best Picture: Musical or Comedy (Winner)",
    label: "Best Picture: Musical or Comedy (Nominated)",
  },
  {
    value: "Best Picture: Musical or Comedy (Winner)",
    label: "Best Picture: Musical or Comedy (Winner)",
  },
];

export const awardOptions: object[] = [
  {
    label: "Oscars",
    options: oscarOptions,
  },
  {
    label: "Golden Globes",
    options: globesOptions,
  },
];

export const ratingOptions: DropdownOption[] = [
  {
    value: "avg",
    label: (
      <span className="filter-flex">
        Average{" "}
        <img
          className="filter-icon"
          src={require(`../media/average.png`)}
          alt={"test"}
        />
      </span>
    ),
  },
  {
    value: "jeff",
    label: (
      <span className="filter-flex">
        Jeff D. Lowe{" "}
        <img
          className="filter-icon"
          src={require(`../media/jdl.png`)}
          alt={"test"}
        />
      </span>
    ),
  },
  {
    value: "kenjac",
    label: (
      <span className="filter-flex">
        KenJac{" "}
        <img
          className="filter-icon"
          src={require(`../media/kenjac.png`)}
          alt={"test"}
        />
      </span>
    ),
  },
];

export const sortOptions: DropdownOption[] = [
  { value: "rating@ASC", label: "High to Low (Rating)" },
  { value: "rating@DESC", label: "Low to High (Rating)" },
  { value: "year@ASC", label: "Oldest to Newest" },
  { value: "year@DESC", label: "Newest to Oldest" },
];
